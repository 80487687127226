/**
  * Genera el código visual para actividades
  * @param $type (string actividad) : hace referencia a la actividad correspondiente
  */
@mixin actividad($type) {

    background: var(--gc-color-secondary-default);

    .checkbox { grid-area: checkbox; }
    .trab_nombre { grid-area: tnombre; }
    .trab_cod { grid-area: tcod; }
    .trab_ropo { grid-area: tropo; }
    .trab_select { grid-area: tselect; }

    .gc-actividad-content {
        &::part(background) {
            transition: all 0.4s ease-out;
          }
        --background: var(--gc-color-layout-body);
        --padding-start: var(--gc-size-padding-m);
        --padding-end: var(--gc-size-padding-m);
        --padding-top: var(--gc-size-padding-m);
        --padding-bottom: var(--gc-size-padding-xl);
        overflow: hidden;
        border-radius: var(--gc-size-border-radius-cero);
        .content {
            display: flex;
            flex-direction: column;
            gap: var(--gc-size-gap-xl);
            .content-section {
                .section-message {
                    margin: var(--gc-size-margin-cero);
                    margin-bottom: var(--gc-size-margin-s);
                    font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
                    font-family: var(--gc-font-poppins-medium);
                    color: var(--gc-color-step-800);
                }
                .section-content {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: var(--gc-size-gap-l) var(--gc-size-gap-m);

                    min-height: 0;  /* NEW */
                    min-width: 0;   /* NEW; needed for Firefox */

                    .content-input {
                        &.toggle {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: var(--gc-size-gap-m);

                            .message {
                                margin: var(--gc-size-margin-cero);
                                font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
                                font-family: var(--gc-font-poppins-regular);
                                color: var(--gc-color-layout-text);

                                &.checked {
                                    color: var(--gc-color-accent-default);
                                    font-family: var(--gc-font-poppins-medium);
                                }
                            }
                        }
                    }

                    @if $type == fitosanitario or $type == post-cosecha or $type == semilla-tratada or $type == fertilizacion {
                        .content-pdf{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            height: fit-content;
                            gap: var(--gc-size-gap-m);
                            background: var(--gc-color-step-50);
                            border-radius: var(--gc-size-border-radius-s);
                            padding: var(--gc-size-padding-m);

                            .pdf-info{
                                .info-title {
                                    margin: var(--gc-size-margin-cero);
                                    color: var(--gc-color-layout-text);
                                    font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
                                    font-family: var(--gc-font-poppins-semibold);
                                }
                                .info-desc {
                                    margin: var(--gc-size-margin-cero);
                                    color: var(--gc-color-layout-text);
                                    font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
                                    font-family: var(--gc-font-poppins-medium);
                                }
                            }

                            .pdf-btn {
                                flex: 0;
                                .btn-icon {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    gap: var(--gc-size-gap-s);
                                    .icon-img {
                                        width: 40px;
                                        --gc-color-icon-fill: var(--gc-color-red-default);
                                        --gc-color-icon-fill-shade: var(--gc-color-red-shade);
                                        --gc-color-icon-fill-shade-contrast: var(--gc-color-red-shade-contrast);
                                    }
                                    .icon-title {
                                        margin: var(--gc-size-margin-cero);
                                        color: var(--gc-color-accent-default-contrast);
                                        font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
                                        font-family: var(--gc-font-poppins-semibold);
                                    }
                                }
                            }
                        }
                        .content-tabla{
                            font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));

                            .headerTabla {
                                padding-left: 8px;
                                display: none;
                                grid-template-columns: 15px 2fr 1fr 1fr 1fr;
                                gap: 4px;
                                padding-right: 8px;
                                font-size: min(var(--gc-size-font-xs), var(--gc-size-font-max-xs));
                                font-family: var(--gc-font-poppins-semibold);
                                color: var(--gc-color-step-500);
                                @media screen and (min-width: 36em){
                                    display: grid;
                                }
                            }
                            .bodyTabla{
                                display: grid;
                                gap: 2px;

                                .filaTabla{
                                    display: grid;
                                    background-color: var(--gc-color-step-50);
                                    padding: 8px;
                                    border-radius: 4px;
                                    font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
                                    color: var(--gc-color-layout-text);
                                    font-family: var(--gc-font-poppins-semibold);
                                    margin: 0;

                                    &.odd{
                                        background-color: var(--gc-color-step-100);
                                    }
                                    gap: 4px;
                                    grid-template-columns: auto 2fr 1fr 1fr 1fr;
                                    grid-template-areas: "checkbox tnombre tnombre tnombre tnombre"
                                                        "checkbox tcod tcod tcod tcod"
                                                        "checkbox tropo tropo tropo tropo"
                                                        "checkbox tselect tselect tselect tselect";

                                    @media screen and (min-width: 36em){
                                        grid-template-columns: auto 2fr 1fr 1fr 1fr;
                                        grid-template-areas: 'checkbox tnombre tcod tropo tselect';
                                    }
                                    .checkbox{
                                        grid-area: checkbox;
                                    }
                                    span {
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                        .unidad-riego{
                            display: grid;
                            gap: var(--gc-size-gap-m);
                            grid-template-columns: 1fr 1fr;
                        }
                    }

                    @media screen and (min-width: 36em) {
                        grid-template-columns: repeat(2, 1fr);

                        @if $type == fitosanitario {
                            &.cantidad {
                                grid-template-columns: 1fr;
                            }
                        }

                        @if $type == fitosanitario && $type == post-cosecha {
                            &.content-cantidad {
                                grid-template-columns: 1fr;
                            }
                            &.otros_medios {
                                grid-template-columns: 1fr 1fr;
                            }
                            &.tabla{
                                grid-template-columns: 1fr;
                            }
                            &.unidad-riego{
                                grid-template-columns: 1fr;
                            }
                        }
                    }

                    @media screen and (min-width: 75em) {
                        grid-template-columns: repeat(4, 1fr);
                        @if $type == fitosanitario && $type == post-cosecha{
                            &.pdfProducto{
                                grid-template-columns: 1fr 3fr;
                            }
                            &.content-cantidad {
                                grid-template-columns: repeat(4, 1fr);
                            }

                        }

                        @if $type == fertilizacion {
                            &.riego{
                                grid-template-columns: repeat(3 , 1fr);
                                .unidad-riego{

                                    grid-template-columns: repeat(2, 1fr);
                                }
                            }

                        }
                    }
                }
                .section-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .margin-bottom {
                margin-bottom: var(--gc-size-margin-l);
            }
            .margin-top {
                margin-top: var(--gc-size-margin-l);
            }
            .ocultar {
                display: none;
            }
            .error {
                color: var(--gc-color-error-default);
            }
        }
    }

    .gc-actividad-footer {
        .footer-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--gc-size-gap-s);
            padding: var(--gc-size-padding-m) var(--gc-size-padding-xl);
            padding-bottom: calc(var(--gc-size-padding-m) + var(--ion-safe-area-bottom));
            background: var(--gc-color-layout-content);
            border-top: 2px solid var(--gc-color-step-150);
            ion-button {
                width: 100%;
            }
        }
        @media screen and (min-width: 22.5em) {
            .footer-btn {
                justify-content: flex-end;
                ion-button {
                    width: auto;
                }
            }
          }
    }
}
