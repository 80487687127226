/**
 * Este archivo contiene los tokens de la app según categorias
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 // color
 @import "./color/themes/default/gc-default-light.scss";
 @import "./color/themes/default/gc-default-dark.scss";
 @import "./color/themes/globalcampo/gc-globalcampo-light.scss";
 @import "./color/themes/globalcampo/gc-globalcampo-dark.scss";
 @import "./color/themes/globalcoffee/gc-globalcoffee-light.scss";
 @import "./color/themes/globalcoffee/gc-globalcoffee-dark.scss";
 @import "./color/themes/vinedo/gc-vinedo-light.scss";
 @import "./color/themes/vinedo/gc-vinedo-dark.scss";
 @import "./color/themes/almendros/gc-almendros-light.scss";
 @import "./color/themes/almendros/gc-almendros-dark.scss";
 @import "./color/themes/trigo/gc-trigo-light.scss";
 @import "./color/themes/trigo/gc-trigo-dark.scss";
 @import "./color/themes/ocean/gc-ocean-light.scss";
 @import "./color/themes/ocean/gc-ocean-dark.scss";
 @import "./color/themes/atlantis/gc-atlantis-light.scss";
 @import "./color/themes/atlantis/gc-atlantis-dark.scss";
 @import "./color/themes/rose/gc-rose-light.scss";
 @import "./color/themes/rose/gc-rose-dark.scss";
 @import "./color/themes/cherry/gc-cherry-light.scss";
 @import "./color/themes/cherry/gc-cherry-dark.scss";
 @import "./color/themes/ice/gc-ice-light.scss";
 @import "./color/themes/ice/gc-ice-dark.scss";
 @import "./color/themes/inferno/gc-inferno-light.scss";
 @import "./color/themes/inferno/gc-inferno-dark.scss";
 @import "./color/themes/cyberpunk/gc-cyberpunk-light.scss";
 @import "./color/themes/cyberpunk/gc-cyberpunk-dark.scss";
 @import "./color/themes/gicoop/gc-gicoop-light.scss";
 @import "./color/themes/gicoop/gc-gicoop-dark.scss";
 @import "./color/icons/icon-theme.scss";

 // font
 @import "./font/gc-primary-font.scss"; // default font

 // size
 @import "./size/gc-border.scss"; // border
 @import "./size/gc-font.scss"; // font
 @import "./size/gc-gap.scss"; // gap
 @import "./size/gc-icon.scss"; // icon
 @import "./size/gc-margin.scss"; // margin
 @import "./size/gc-padding.scss"; // padding

// media query
 @import "./media-query/gc-media-queries.scss"; // media query
