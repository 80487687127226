/**
 * Este archivo contiene las clases globales utilizadas
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 @import "./classes-alert.scss"; // ALERT
 @import "./classes-app.scss"; // APP
 @import "./classes-button.scss"; // BUTTON
 @import "./classes-modal.scss"; // MODAL
 @import "./classes-searchbar.scss"; // SEARCHBAR
 @import "./classes-toggle.scss"; // TOGGLE
 @import "./classes-popover.scss"; // POPOVER

 