/**
 * Este archivo contiene los tokens relativos a la font-family de las fuentes: Poppins
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 @import "src/fonts/poppins/poppins.scss";

 :root {

    -webkit-font-smoothing: antialiased;

    --ion-font-family: 'Poppins-Regular';

    --gc-font-poppins-thin: 'Poppins-Thin', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-extralight: 'Poppins-ExtraLight', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-light: 'Poppins-Light', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-regular: 'Poppins-Regular', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-medium: 'Poppins-Medium', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-semibold: 'Poppins-SemiBold', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-bold: 'Poppins-Bold', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-extrabold: 'Poppins-ExtraBold', -apple-system, BlinkMacSystemFont, sans-serif;
    --gc-font-poppins-black: 'Poppins-Black', -apple-system, BlinkMacSystemFont, sans-serif;

}
