/**
 * Este archivo contiene los mixins globales utilizados
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 @import "./mixins-alert.scss"; // ALERT
 @import "./mixins-app.scss"; // APP
 @import "./mixins-button.scss"; // BUTTON
 @import "./mixins-cca-actividad.scss"; // CCA ACTIVIDADES
 @import "./mixins-modal.scss"; // MODAL
 @import "./mixins-searchbar.scss"; // SEARCHBAR
 @import "./mixins-toggle.scss"; // TOGGLE
