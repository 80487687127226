@import "src/theme/design-system/mixins/mixins-app.scss";

/* Non dynamic variables by device */
:root.plt-desktop {
    --gc-size-font-modal-header-title: 18px;
}
:root.plt-mobile {
    --gc-size-font-modal-header-title: 22px;
}
:root.plt-android {
    --gc-size-font-modal-header-title: 0.97rem;
}


/**
* Genera la estructura por defecto de la modal
*/
@mixin modal-default($modalName:'') {
   .modal-header { @include modal-header($modalName); }
   .modal-content { @include modal-content($modalName, true, true, true); }
   .modal-footer { @include modal-footer($modalName); }
}
/**
  * Genera la estructura de la modal
  * @param $type (custom, modal-s o modal-m): hace referencia al tamaño de la modal, customizada (custom), pequeña (modal-s) o tamaño medio (modal-m)
  * @param $modal (string modal): hace referencia a la modal correspondiente
  */
@mixin modal($type, $modal:"") {
    //Tokens relativos al tamaño custom
    --gc-size-modal-s-width: 360px;
    --gc-size-modal-s-height: auto;
    --gc-size-modal-l-width: 700px;
    --gc-size-modal-custom-loading-max-width: 300px;
    --gc-size-modal-custom-loading-height: auto;

    @if $type == custom {

        @if $modal == loading {

            &::part(content){
                border-top: 8px solid var(--gc-color-accent-default);
                border-radius: var(--gc-size-border-radius-s);
                padding: var(--gc-size-padding-m);
                background: var(--gc-color-layout-body);
                height: var(--gc-size-modal-custom-loading-height);
                max-width: var(--gc-size-modal-custom-loading-max-width);
            }

        }

        @if $modal == alert {

            &::part(content){
                border-top: 8px solid var(--gc-color-warn-default);
                border-radius: var(--gc-size-border-radius-s);
                padding: var(--gc-size-padding-m);
                background: var(--gc-color-layout-body);
                height: var(--gc-size-modal-custom-loading-height);
                max-width: var(--gc-size-modal-custom-loading-max-width);
            }

        }



        @if $modal == login {

            &::part(content){
                height: auto;
                width: auto;
                max-height: 584px;
                border-radius: var(--gc-size-border-radius-s);
                background: var(--gc-color-layout-body);
                margin-inline: var(--gc-size-margin-m);

                overflow-x: hidden;
                overflow-y: auto;

                scrollbar-color: var(--gc-color-accent-default) var(--gc-color-layout-body);
                scrollbar-width: 8px;

                &::-webkit-scrollbar{
                    width: 8px;
                    background: var(--gc-color-accent-default);
                }

                &::-webkit-scrollbar-thumb{
                    border-radius: 8px;
                    background: var(--gc-color-accent-default);
                }
            }

        }

        @if $modal == pdf-viewer or $modal == iframe-viewer or $modal == explotacion-register{

            &::part(content){
                border-radius: var(--gc-size-border-radius-s);
                background: var(--gc-color-layout-body);
                height: 100%;
                width: 100%;
            }

        }

        @if $modal == fecha-hora {
            &::part(content){
                border-radius: var(--gc-size-border-radius-s);
                padding: var(--gc-size-padding-m);
                background: var(--gc-color-layout-body);
                height: auto;
                width: auto;
                margin-inline: var(--gc-size-margin-m);
            }
        }

        @if $modal == new-button {
            --height: auto;
            &::part(content) {
                border-top-left-radius: var(--gc-size-border-radius-m, 16px);
                border-top-right-radius:var(--gc-size-border-radius-m, 16px);
                border-bottom-right-radius: var(--gc-size-border-radius-cero, 0);
                border-bottom-left-radius: var(--gc-size-border-radius-cero, 0);
                background: var(--gc-color-layout-body);
            }
            &::part(handle) {
                display: none;
            }
        }

        @if $modal == gc-main-menu-dropdown {
            :root {
                --gc-size-header-main: 60px;
            }
            --height: auto;
            top: var(--gc-size-header-main);
            margin-inline: var(--gc-size-margin-s);
            border-radius: var(--gc-size-border-radius-l) var(--gc-size-border-radius-l) var(--gc-size-border-radius-cero) var(--gc-size-border-radius-cero);
            &::part(content){
                position: absolute;
                top: 0;
                width: 100%;
                height: auto;
                background: var(--gc-color-layout-body);
                border-radius: var(--gc-size-border-radius-cero);
                --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
            }
        }

    }

    @if $type == modal-s {

        &::part(content){
            --background: var(--gc-color-layout-body);
            border-radius: var(--gc-size-border-radius-s);
        }

        @media screen and (min-width: 36em) {

            &::part(content){
                height: var(--gc-size-modal-s-height);
                width: var(--gc-size-modal-s-width);
            }

        }

        @if $modal == gc-user-profile-modal {
            &::part(content){
                border-radius: var(--gc-size-border-radius-s);
                --background: var(--gc-color-layout-body);
                height: auto;
                width: var(--gc-size-modal-s-width);
                margin-inline: var(--gc-size-margin-m);
            }
        }
    }

    @if $type == modal-m {

        &::part(content){
            --background: var(--gc-color-layout-body);
            border-radius: var(--gc-size-border-radius-s);
        }

        @if $modal == menu {
            &::part(content){
                --background: linear-gradient(var(--gc-color-primary-shade), var(--gc-color-secondary-tint));
            }
        }

        @if $modal == selector {

            .modal-header{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: var(  --gc-size-margin-xl);
                gap: var(--gc-size-gap-m);

                @include safe-area-top();

                    .header-title {
                        margin: var(--gc-size-margin-cero);
                        color: var(--gc-color-layout-text);
                        font-size: var(--gc-size-font-modal-header-title);
                        font-family: var(--gc-font-family-primary-semibold);
                    }
                    .header-cancel {
                        cursor: pointer;
                        --gc-color-icon-fill: var(--gc-color-accent-default);
                        flex: 0 0 var(--gc-size-icon-m);
                        font-size: min(var(--gc-size-icon-m), var(--gc-size-icon-max-m));
                        --ionicon-stroke-width: 40px;
                    }
            }

            .modal-message {
                display: flex;
                align-items: center;
                margin: var(--gc-size-margin-cero) var(  --gc-size-margin-xl) var(  --gc-size-margin-m) var(  --gc-size-margin-xl);

                .message-label {
                    margin: var(--gc-size-margin-cero);
                    color: var(--gc-color-accent-shade);
                    font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
                    font-family: var(--gc-font-family-primary-medium);
                }
            }

            .modal-segment {
                margin: var(--gc-size-margin-cero) var(  --gc-size-margin-xl) var(  --gc-size-margin-m) var(  --gc-size-margin-xl);
            }

            .modal-searchbar {
                margin: var(--gc-size-margin-cero) var(  --gc-size-margin-xl) var(  --gc-size-margin-m) var(  --gc-size-margin-xl);
            }

            .modal-content {
                display: flex;
                position: relative;
                flex-direction: column;
                gap: var(--gc-size-gap-m);
                margin: var(--gc-size-margin-cero) var(--gc-size-margin-xl) var(--gc-size-margin-xl) var(--gc-size-margin-xl);

                @include scrollbar();
                @include safe-area-bottom();

                .content-list {
                    display: flex;
                    flex-direction: column;
                    gap: var(--gc-size-gap-s);
                    background: none;

                    .list-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        background: var(--gc-color-accent-opacity-10);
                        border-radius: var(--gc-size-border-radius-xs);
                        padding: var(--gc-size-padding-s) var(--gc-size-padding-m);
                        border: 2px solid transparent;
                        gap: var(--gc-size-gap-m);

                            .item-label {
                                margin: var(--gc-size-margin-cero);
                                font-size: min(var(--gc-size-font-l), var(--gc-size-font-max-l));
                                font-family: var(--gc-font-family-primary-medium);
                                color: var(--gc-color-layout-text);
                                word-break: break-word;
                            }

                            .item-description {
                                display: flex;
                                flex-direction: column;
                                gap: var(--gc-size-gap-xs);

                                .description-label {
                                    margin: var(--gc-size-margin-cero);
                                    font-size: min(var(--gc-size-font-l), var(--gc-size-font-max-l));
                                    font-family: var(--gc-font-family-primary-medium);
                                    color: var(--gc-color-layout-text);
                                }

                                .description-sublabel {
                                    margin: var(--gc-size-margin-cero);
                                    font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
                                    font-family: var(--gc-font-family-primary-regular);
                                    color: var(--gc-color-layout-text);
                                    &.caducado {
                                        color: var(--gc-color-error-default);
                                    }
                                }
                            }

                            .item-icon {
                                flex: 0 0 var(--gc-size-icon-s);
                                --ionicon-stroke-width: 40px;
                                font-size: min(var(--gc-size-icon-s), var(--gc-size-icon-max-s));
                                --gc-color-icon-fill: var(--gc-color-step-500);
                            }

                        &.select {
                            padding: var(--gc-size-padding-m) var(--gc-size-padding-l);
                            justify-content: space-between;

                            &:hover {
                                cursor: pointer;
                                border: 2px solid var(--gc-color-accent-tint);
                                background: var(--gc-color-accent-opacity-10);
                            }

                            &.selected {
                                border: 2px solid var(--gc-color-accent-default);
                                background: var(--gc-color-accent-opacity-10);
                            }
                        }

                        &.check {
                            padding: var(--gc-size-padding-m) var(--gc-size-padding-l);

                            .item-icon { display: none; }

                            &:hover {
                                cursor: pointer;
                                border: 2px solid var(--gc-color-accent-tint);
                                background: var(--gc-color-accent-opacity-10);
                            }

                            &.selected {
                                border: 2px solid var(--gc-color-accent-default);
                            }
                        }

                        &.error{
                            border-left: 4px solid  var(--gc-color-error-default);
                        }

                        &.info {
                            border-left: 4px solid  var(--gc-color-info-default);
                        }

                    }
                }

                .content-info {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    &.content-buscador-info {
                        flex-direction: column;
                        text-align: start;
                        gap: var(--gc-size-gap-m);
                    }

                    .info-label {
                        margin: var(--gc-size-margin-cero);
                        color: var(--gc-color-layout-text);
                        font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
                        font-family: var(--gc-font-family-primary-medium);
                    }
                }

                .content-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: var(--gc-size-gap-s);
                }

            }

        }

    }

    @if $type == modal-l {

        &::part(content){
            --background: var(--gc-color-layout-body);
            border-radius: var(--gc-size-border-radius-s);
        }

       @if $modal == lonjas-modal {
            &::part(content){
                border-radius: var(--gc-size-border-radius-l);
                --background: var(--gc-color-layout-body);
                height: 650px;
                width: var(--gc-size-modal-l-width);
                margin-inline: var(--gc-size-margin-m);
            }
        }
    }
}

/**
  * Genera los estilos del header de la modal
  * @param $modal (string modal): hace referencia a la modal correspondiente
  */
@mixin modal-header($modal) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: calc(var(--gc-size-padding-xl) + var(--ion-safe-area-top)) var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl);
        gap: var(--gc-size-gap-m);
        .header-title {
            margin: var(--gc-size-margin-cero);
            padding: var(--gc-size-padding-cero);
            color: var(--gc-color-layout-text);
            @if $modal == mensaje-siex-error {
                color: var(--gc-color-error-default);
            }
            @if $modal == menu {
                color: var(--gc-color-primary-default-contrast);
            }
            font-size: var(--gc-size-font-modal-header-title);
            font-family: var(--gc-font-family-primary-semibold);
        }
        .header-cancel {
            cursor: pointer;
            --gc-color-icon-fill: var(--gc-color-accent-default);
            @if $modal == mensaje-siex-error {
                --gc-color-icon-fill: var(--gc-color-error-default);
            }
            @if $modal == menu {
                --gc-color-icon-fill: var(--gc-color-accent-default-contrast);
            }
            font-size: min( var(--gc-size-icon-m), var(--gc-size-icon-max-m));
            --ionicon-stroke-width: 40px;
        }
        @if $modal == gc-user-profile-modal {
            padding: var(--gc-size-padding-l);
            padding-bottom: var(--gc-size-padding-cero);
            justify-content: flex-end;
            .header-cancel {
                font-size: min(var(--gc-size-icon-s), var(--gc-size-icon-max-s));
            }
        }
}

/**
  * Genera los estilos del contenido de la modal
  * @param $modal (string modal): hace referencia a la modal correspondiente
  * @param $header (boolean): hace referencia a si la modal tiene un modal-header (header) o no
  * @param $scroll (boolean): hace referencia a si el contenido de la va a necesitar scroll o no
  * @param $footer (boolean): hace referencia a si la modal tiene un modal-footer (footer) o no
  */
@mixin modal-content($modal, $header, $scroll, $footer){
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
        gap: var(--gc-size-gap-m);
        padding: var(--gc-size-padding-cero) var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl);

        @if $header == false {
            padding: calc(var(--gc-size-padding-xl) + var(--ion-safe-area-top)) var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl);
        }

        @if $scroll == true {
            @include scrollbar();
        }

        @if $footer == false {
            padding: var(--gc-size-padding-cero) var(--gc-size-padding-xl) calc(var(--gc-size-padding-xl) + var(--ion-safe-area-bottom)) var(--gc-size-padding-xl);
        }

        @if $modal == filtros-parcelas {
            padding: var(--gc-size-padding-s) var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl);
        }

        @if $modal == menu {
            padding: var(--gc-size-padding-s) var(--gc-size-padding-s) calc(var(--gc-size-padding-s) + var(--ion-safe-area-bottom)) var(--gc-size-padding-s);
        }

        @if $modal == gc-user-profile-modal {
            gap: var(--gc-size-gap-cero);
            padding: var(--gc-size-padding-cero);
        }
}

/**
  * Genera los estilos del footer de la modal
  * @param $modal (string modal): hace referencia a la modal correspondiente
  */
@mixin modal-footer($modal:""){
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: var(--gc-size-gap-s);
    border-top: 2px solid var(--gc-color-step-50);
    padding: var(--gc-size-padding-m) var(--gc-size-padding-m) calc(var(--gc-size-padding-m) + var(--ion-safe-area-bottom)) var(--gc-size-padding-m);

    @if $modal == buscador-parcelas {
        padding: var(--gc-size-padding-xl) var(--gc-size-padding-xl) calc(var(--gc-size-padding-xl) + var(--ion-safe-area-bottom)) var(--gc-size-padding-xl);
    }
}
