/**
 * Este archivo contiene las clases relativas a la modal
 * Las clases usan los mixins presentes en mixins-modal.scss para construir la visual
 * Por favor, NO editar este archivo salvo previa consulta.
 */

@import "src/theme/design-system/mixins/mixins-modal.scss";

:root {
  #modal {

    @include modal(modal-m, modal);

    &.loading { @include modal(custom, loading) }
    &.login { @include modal(custom, login) }
    &.actividades-menu { @include modal(modal-m, actividades-menu) }
    &.filtros-actividades { @include modal(modal-m, actividades-menu) }
    &.duplicar-actividad { @include modal(modal-s, duplicar-actividad) }
    &.fecha-hora {@include modal(custom, fecha-hora)}
    &.filtros-fechas {@include modal(modal-m, filtros-fechas)}
    &.filtros-parcelas { @include modal(modal-s, filtros-parcelas) }
    &.listado-mensajes { @include modal(modal-m, listado-mensajes ) }
    &.lonjas-modal { @include modal(modal-l, lonjas-modal ) }
    &.menu { @include modal(modal-m, menu) }
    &.selector { @include modal(modal-m, selector) }
    &.buscador { @include modal(modal-m, selector) }
    &.pdf-viewer { @include modal(custom, pdf-viewer) }
    &.iframe-viewer { @include modal(custom, iframe-viewer) }
    &.mensaje-siex-error{ @include modal(modal-m, mensaje-siex-error)}
    &.form-logalty{@include modal(modal-m, form-logalty)}
    &.new-button{ @include modal(custom, new-button)}
    &.buscador-parcelas { @include modal(modal-m, buscador-parcelas); }
    &.gc-user-profile-modal{ @include modal(modal-s, gc-user-profile-modal); }
    &.alert{@include modal(custom, alert);}
    &.notificar-errores-actividad { @include modal(modal-s, notificar-errores-actividad) }
    &.explotacion-register{ @include modal(custom, explotacion-register); }
    &.show-instalaciones-selector{ @include modal(modal-m, show-instalaciones-selector); }
    &.invitado{ @include modal(modal-m, invitado); }
  }

  #modalinvitado {
    &.invitado{ @include modal(modal-m, invitado); }
  }

  #gc-main-menu-dropdown { @include modal(custom, gc-main-menu-dropdown); }

  #modal-sheet {
    &.new-button{ @include modal(custom, new-button)}
  }

  ion-modal.modal-default.show-modal:nth-of-type(n + 2):last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.15) !important;
  }
}
