/**
 * Este archivo contiene las clases relativas al searchbar
 * Las clases usan los mixins presentes en mixins-searchbar.scss para construir la visual
 * Por favor, NO editar este archivo salvo previa consulta.
 */

@import "src/theme/design-system/mixins/mixins-searchbar.scss";

:root {
    .searchbar {
        &-s {   @include searchbar(s); }
        &, &-m { @include searchbar(m); }
        &-l {  @include searchbar(l); }
    }
}
      