/**
 * Este archivo contiene los tokens relativos a la font-family de las fuentes: Poppins
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 @import "/src/fonts/poppins/poppins.scss";

 :root {

    -webkit-font-smoothing: antialiased;

    --gc-font-family-primary: var(--gc-font-poppins-regular);
    --gc-font-family-primary-regular: var(--gc-font-poppins-regular);
    --gc-font-family-primary-thin: var(--gc-font-poppins-thin);
    --gc-font-family-primary-extralight: var(--gc-font-poppins-extralight);
    --gc-font-family-primary-light: var(--gc-font-poppins-light);
    --gc-font-family-primary-medium: var(--gc-font-poppins-medium);
    --gc-font-family-primary-semibold: var(--gc-font-poppins-semibold);
    --gc-font-family-primary-bold: var(--gc-font-poppins-bold);
    --gc-font-family-primary-extrabold: var(--gc-font-poppins-extrabold);
    --gc-font-family-primary-black: var(--gc-font-poppins-black);

    --ion-font-family: var(--gc-font-family-primary);
}
