
/* Core CSS - Ionic components */
    @import "@ionic/angular/css/core.css";
    @import "@ionic/angular/css/normalize.css";
    @import '@ionic/angular/css/display.css';

/* CSS utils - Borrar si no se usan (opcional)*/
    @import "@ionic/angular/css/structure.css";
    @import "@ionic/angular/css/typography.css";
    @import "@ionic/angular/css/padding.css";
    @import "@ionic/angular/css/float-elements.css";
    @import "@ionic/angular/css/text-alignment.css";
    @import "@ionic/angular/css/text-transformation.css";
    @import "@ionic/angular/css/flex-utils.css";

/* ------------------------------------------------------------------------ */
/* Tokens - Classes - Mixins*/
    @import 'src/theme/design-system/classes/global-classes.scss'; //classes
    @import 'src/theme/design-system/mixins/global-mixins.scss'; //mixins


/* ------------------------------------------------------------------------ */
/* Theme variants */


