/**
 * Este archivo contiene los tokens relativos a los tamaños de padding
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root.plt-desktop {

  --gc-size-padding-default: 16px;
  --gc-size-padding-cero: 0px;
  --gc-size-padding-xxs: 2px;
  --gc-size-padding-xs: 4px;
  --gc-size-padding-s: 8px;
  --gc-size-padding-m: 12px;
  --gc-size-padding-l: 16px;
  --gc-size-padding-xl: 24px;
  --gc-size-padding-xxl: 32px;
  --gc-size-padding-xxxl: 48px;

 }

 :root.plt-mobile {

  --gc-size-padding-default: 16px;
  --gc-size-padding-cero: 0px;
  --gc-size-padding-xxs: 2px;
  --gc-size-padding-xs: 4px;
  --gc-size-padding-s: 8px;
  --gc-size-padding-m: 12px;
  --gc-size-padding-l: 16px;
  --gc-size-padding-xl: 24px;
  --gc-size-padding-xxl: 32px;
  --gc-size-padding-xxxl: 48px;
  
}
