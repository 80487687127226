/**
 * Este archivo contiene las clases relativas al button
 * Las clases usan los mixins presentes en mixins-button.scss para construir la visual
 * Por favor, NO editar este archivo salvo previa consulta.
 */

@import "src/theme/design-system/mixins/mixins-button.scss";

:root {
    .btn {
        &-s {
            &-default-solid { @include  btn(label, s, accent, solid); }
            &-default-outline { @include  btn(label, s, accent, outline); }
            &-default-clear { @include  btn(label, s, accent, clear); }

            &-accent-solid { @include  btn(label, s, accent, solid); }
            &-accent-outline { @include  btn(label, s, accent, outline); }
            &-accent-clear { @include  btn(label, s, accent, clear); }

            &-error-solid { @include  btn(label, s, error, solid); }
            &-error-outline { @include  btn(label, s, error, outline); }
            &-error-clear { @include  btn(label, s, error, clear); }

            &-warn-solid { @include  btn(label, s, warn, solid); }
            &-warn-outline { @include  btn(label, s, warn, outline); }
            &-warn-clear { @include  btn(label, s, warn, clear); }

            &-success-solid { @include  btn(label, s, success, solid); }
            &-success-outline { @include  btn(label, s, success, outline); }
            &-success-clear { @include  btn(label, s, success, clear); }

            &-info-solid { @include  btn(label, s, info, solid); }
            &-info-outline { @include  btn(label, s, info, outline); }
            &-info-clear { @include  btn(label, s, info, clear); }

            &-white-solid { @include  btn(label, s, white, solid); }
            &-white-outline { @include  btn(label, s, white, outline); }
            &-white-clear { @include  btn(label, s, white, clear); }
        }
        &, &-m {
            &-default-solid { @include  btn(label, m, accent, solid); }
            &-default-outline { @include  btn(label, m, accent, outline); }
            &-default-clear { @include  btn(label, m, accent, clear); }

            &-accent-solid { @include  btn(label, m, accent, solid); }
            &-accent-outline { @include  btn(label, m, accent, outline); }
            &-accent-clear { @include  btn(label, m, accent, clear); }

            &-error-solid { @include  btn(label, m, error, solid); }
            &-error-outline { @include  btn(label, m, error, outline); }
            &-error-clear { @include  btn(label, m, error, clear); }

            &-warn-solid { @include  btn(label, m, warn, solid); }
            &-warn-outline { @include  btn(label, m, warn, outline); }
            &-warn-clear { @include  btn(label, m, warn, clear); }

            &-success-solid { @include  btn(label, m, success, solid); }
            &-success-outline { @include  btn(label, m, success, outline); }
            &-success-clear { @include  btn(label, m, success, clear); }

            &-info-solid { @include  btn(label, m, info, solid); }
            &-info-outline { @include  btn(label, m, info, outline); }
            &-info-clear { @include  btn(label, m, info, clear); }

            &-white-solid { @include  btn(label, m, white, solid); }
            &-white-outline { @include  btn(label, m, white, outline); }
            &-white-clear { @include  btn(label, m, white, clear); }
        }
        &-l {
            &-default-solid { @include  btn(label, l, accent, solid); }
            &-default-outline { @include  btn(label, l, accent, outline); }
            &-default-clear { @include  btn(label, l, accent, clear); }

            &-accent-solid { @include  btn(label, l, accent, solid); }
            &-accent-outline { @include  btn(label, l, accent, outline); }
            &-accent-clear { @include  btn(label, l, accent, clear); }

            &-error-solid { @include  btn(label, l, error, solid); }
            &-error-outline { @include  btn(label, l, error, outline); }
            &-error-clear { @include  btn(label, l, error, clear); }

            &-warn-solid { @include  btn(label, l, warn, solid); }
            &-warn-outline { @include  btn(label, l, warn, outline); }
            &-warn-clear { @include  btn(label, l, warn, clear); }

            &-success-solid { @include  btn(label, l, success, solid); }
            &-success-outline { @include  btn(label, l, success, outline); }
            &-success-clear { @include  btn(label, l, success, clear); }

            &-info-solid { @include  btn(label, l, info, solid); }
            &-info-outline { @include  btn(label, l, info, outline); }
            &-info-clear { @include  btn(label, l, info, clear); }

            &-white-solid { @include  btn(label, l, white, solid); }
            &-white-outline { @include  btn(label, l, white, outline); }
            &-white-clear { @include  btn(label, l, white, clear); }
        }
    }

    .btn-icon {
        &-s {
            &-default-solid { @include  btn(icon, s, accent, solid); }
            &-default-outline { @include  btn(icon, s, accent, outline); }
            &-default-clear { @include  btn(icon, s, accent, clear); }

            &-accent-solid { @include  btn(icon, s, accent, solid); }
            &-accent-outline { @include  btn(icon, s, accent, outline); }
            &-accent-clear { @include  btn(icon, s, accent, clear); }

            &-error-solid { @include  btn(icon, s, error, solid); }
            &-error-outline { @include  btn(icon, s, error, outline); }
            &-error-clear { @include  btn(icon, s, error, clear); }

            &-warn-solid { @include  btn(icon, s, warn, solid); }
            &-warn-outline { @include  btn(icon, s, warn, outline); }
            &-warn-clear { @include  btn(icon, s, warn, clear); }
        }
        &, &-m {
            &-default-solid { @include  btn(icon, m, accent, solid); }
            &-default-outline { @include  btn(icon, m, accent, outline); }
            &-default-clear { @include  btn(icon, m, accent, clear); }

            &-accent-solid { @include  btn(icon, m, accent, solid); }
            &-accent-outline { @include  btn(icon, m, accent, outline); }
            &-accent-clear { @include  btn(icon, m, accent, clear); }

            &-error-solid { @include  btn(icon, m, error, solid); }
            &-error-outline { @include  btn(icon, m, error, outline); }
            &-error-clear { @include  btn(icon, m, error, clear); }

            &-warn-solid { @include  btn(icon, m, warn, solid); }
            &-warn-outline { @include  btn(icon, m, warn, outline); }
            &-warn-clear { @include  btn(icon, m, warn, clear); }
        }
        &-l {
            &-default-solid { @include  btn(icon, l, accent, solid); }
            &-default-outline { @include  btn(icon, l, accent, outline); }
            &-default-clear { @include  btn(icon, l, accent, clear); }

            &-accent-solid { @include  btn(icon, l, accent, solid); }
            &-accent-outline { @include  btn(icon, l, accent, outline); }
            &-accent-clear { @include  btn(icon, l, accent, clear); }

            &-error-solid { @include  btn(icon, l, error, solid); }
            &-error-outline { @include  btn(icon, l, error, outline); }
            &-error-clear { @include  btn(icon, l, error, clear); }

            &-warn-solid { @include  btn(icon, l, warn, solid); }
            &-warn-outline { @include  btn(icon, l, warn, outline); }
            &-warn-clear { @include  btn(icon, l, warn, clear); }
        }
    }
}
