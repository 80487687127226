/**
 * Este archivo contiene las clases relativas al toggle
 * Las clases usan los mixins presentes en mixins-toggle.scss para construir la visual
 * Por favor, NO editar este archivo salvo previa consulta.
 */

@import "src/theme/design-system/mixins/mixins-toggle.scss";

:root {
    .toggle { @include toggle(); }
}
