:root body {

  --gc-color-icon-lock-primary: var(--gc-color-accent-tone);
  --gc-color-icon-lock-secondary: var(--gc-color-step-350);

  --gc-color-icon-fill: var(--gc-color-accent-default);
  --gc-color-icon-fill-contrast: var(--gc-color-accent-default-contrast);
  --gc-color-icon-fill-tone: var(--gc-color-accent-tone);
  --gc-color-icon-fill-tone-contrast: var(--gc-color-accent-tone-contrast);
  --gc-color-icon-fill-tint: var(--gc-color-accent-tint);
  --gc-color-icon-fill-tint-contrast: var(--gc-color-accent-tint-contrast);
  --gc-color-icon-fill-shade: var(--gc-color-accent-shade);
  --gc-color-icon-fill-shade-contrast: var(--gc-color-accent-shade-contrast);
  --gc-color-icon-fill-yellow: var(--gc-color-yellow-default);

  // Accent
  --gc-color-icon-accent-default: var(--gc-color-accent-default);
  --gc-color-icon-accent-opacity-10: var(--gc-color-accent-opacity-10);
  --gc-color-icon-accent-opacity-25: var(--gc-color-accent-opacity-25);
  --gc-color-icon-accent-default-contrast: var(--gc-color-accent-default-contrast);
  --gc-color-icon-accent-shade: var(--gc-color-accent-shade);
  --gc-color-icon-accent-shade-contrast: var(--gc-color-accent-shade-contrast);
  --gc-color-icon-accent-tone: var(--gc-color-accent-tone);
  --gc-color-icon-accent-tone-contrast: var(--gc-color-accent-tone-contrast);
  --gc-color-icon-accent-tint: var(--gc-color-accent-tint);
  --gc-color-icon-accent-tint-contrast: var(--gc-color-accent-tint-contrast);
  --gc-color-icon-outline: var(--gc-color-step-800);
  // Color
  --gc-color-icon-red-default: var(--gc-color-red-default);
  --gc-color-icon-red-tone: var(--gc-color-red-tone);
  --gc-color-icon-red-shade: var(--gc-color-red-shade);
  --gc-color-icon-red-shade-contrast: var(--gc-color-red-shade-contrast);
  --gc-color-icon-yellow-default: var(--gc-color-yellow-default);
  --gc-color-icon-yellow-tone: var(--gc-color-yellow-tone);
  --gc-color-icon-yellow-shade: var(--gc-color-yellow-shade);
  --gc-color-icon-yellow-shade-contrast: var(--gc-color-yellow-shade-contrast);
  --gc-color-icon-brown-default: var(--gc-color-brown-default);
  --gc-color-icon-brown-tone: var(--gc-color-brown-tone);
  --gc-color-icon-brown-shade: var(--gc-color-brown-shade);
  --gc-color-icon-brown-shade-contrast: var(--gc-color-brown-shade-contrast);
  --gc-color-icon-green-default: var(--gc-color-green-default);
  --gc-color-icon-green-tone: var(--gc-color-green-tone);
  --gc-color-icon-green-shade: var(--gc-color-green-shade);
  --gc-color-icon-green-shade-contrast: var(--gc-color-green-shade-contrast);
  --gc-color-icon-black-default: var(--gc-color-black-default);
  --gc-color-icon-black-default-contrast: var(--gc-color-black-default-contrast);
  --gc-color-icon-black-tone: var(--gc-color-black-tone);
  --gc-color-icon-white-default: var(--gc-color-white-default);
  --gc-color-icon-white-default-contrast: var(--gc-color-white-default-contrast);
  --gc-color-icon-skin-default: var(--gc-color-skin-default);
  --gc-color-icon-orange-default: var(--gc-color-orange-default);
  --gc-color-icon-yellow-default: var(--gc-color-yellow-default);
  --gc-color-icon-yellow-default-contrast: var(--gc-color-yellow-default-contrast);
  --gc-color-icon-yellow-tone: var(--gc-color-yellow-tone);
  --gc-color-icon-yellow-tone-contrast: var(--gc-color-yellow-tone-contrast);
  --gc-color-icon-yellow-shade: var(--gc-color-yellow-shade);
  --gc-color-icon-yellow-shade-contrast: var(--gc-color-yellow-shade-contrast);
  --gc-color-icon-yellow-opacity-10: var(--gc-color-yellow-opacity-10);
  --gc-color-icon-yellow-opacity-10-contrast: var(--gc-color-yellow-opacity-10-contrast);

  //setp
  --gc-color-step-25: var(--gc-color-step-25);
  --gc-color-step-50: var(--gc-color-step-50);
  --gc-color-step-100: var(--gc-color-step-100);
  --gc-color-step-250: var(--gc-color-step-250);
  --gc-color-step-300: var(--gc-color-step-300);
  --gc-color-step-350: var(--gc-color-step-350);
  --gc-color-step-500: var(--gc-color-step-500);
  --gc-color-step-700: var(--gc-color-step-700);
}
