/**
 * Este archivo contiene las clases relativas al button
 * Las clases usan los mixins presentes en mixins-button.scss para construir la visual
 * Por favor, NO editar este archivo salvo previa consulta.
 */

@import "src/theme/design-system/mixins/mixins-button.scss";

:root {
    .btn {
        &-s {
            &-default-solid { @include  btn(label, s, accent, solid, dynamic); }
            &-default-outline { @include  btn(label, s, accent, outline, dynamic); }
            &-default-clear { @include  btn(label, s, accent, clear, dynamic); }
            &-default-opacity { @include  btn(label, s, accent, opacity, dynamic); }

            &-globalcaja-solid { @include  btn(label, s, accent, solid, dynamic); }
            &-globalcaja-outline { @include  btn(label, s, accent, outline, dynamic); }
            &-globalcaja-clear { @include  btn(label, s, accent, clear, dynamic); }
            &-globalcaja-opacity { @include  btn(label, s, accent, opacity, dynamic); }

            &-accent-solid { @include  btn(label, s, accent, solid, dynamic); &-static { @include  btn(label, s, accent, solid, static); }}
            &-accent-outline { @include  btn(label, s, accent, outline, dynamic); }
            &-accent-clear { @include  btn(label, s, accent, clear, dynamic); &-static { @include  btn(label, s, accent, clear, static); }}
            &-accent-opacity { @include  btn(label, s, accent, opacity, dynamic); &-static { @include  btn(label, s, accent, opacity, static); }}

            &-error-solid { @include  btn(label, s, error, solid, dynamic); }
            &-error-outline { @include  btn(label, s, error, outline, dynamic); }
            &-error-clear { @include  btn(label, s, error, clear, dynamic); }
            &-error-opacity { @include  btn(label, s, error, opacity, dynamic); }

            &-warn-solid { @include  btn(label, s, warn, solid, dynamic); }
            &-warn-outline { @include  btn(label, s, warn, outline, dynamic); }
            &-warn-clear { @include  btn(label, s, warn, clear, dynamic); }
            &-warn-opacity { @include  btn(label, s, warn, opacity, dynamic); }

            &-success-solid { @include  btn(label, s, success, solid, dynamic); }
            &-success-outline { @include  btn(label, s, success, outline, dynamic); }
            &-success-clear { @include  btn(label, s, success, clear, dynamic); }
            &-success-opacity { @include  btn(label, s, success, opacity, dynamic); }

            &-info-solid { @include  btn(label, s, info, solid, dynamic); }
            &-info-outline { @include  btn(label, s, info, outline, dynamic); }
            &-info-clear { @include  btn(label, s, info, clear, dynamic); }
            &-info-opacity { @include  btn(label, s, info, opacity, dynamic); }

            &-white-solid { @include  btn(label, s, white, solid, dynamic); }
            &-white-outline { @include  btn(label, s, white, outline, dynamic); }
            &-white-clear { @include  btn(label, s, white, clear, dynamic); }
            &-white-opacity { @include  btn(label, s, white, opacity, dynamic); }
        }
        &, &-m {
            &-default-solid { @include  btn(label, m, accent, solid, dynamic); }
            &-default-outline { @include  btn(label, m, accent, outline, dynamic); }
            &-default-clear { @include  btn(label, m, accent, clear, dynamic); }
            &-default-opacity { @include  btn(label, m, accent, opacity, dynamic); }

            &-globalcaja-solid { @include  btn(label, m, accent, solid, dynamic); }
            &-globalcaja-outline { @include  btn(label, m, accent, outline, dynamic); }
            &-globalcaja-clear { @include  btn(label, m, accent, clear, dynamic); }
            &-globalcaja-opacity { @include  btn(label, m, accent, opacity, dynamic); }

            &-accent-solid { @include  btn(label, m, accent, solid, dynamic); }
            &-accent-outline { @include  btn(label, m, accent, outline, dynamic); }
            &-accent-clear { @include  btn(label, m, accent, clear, dynamic); }
            &-accent-opacity { @include  btn(label, m, accent, opacity, dynamic); }

            &-error-solid { @include  btn(label, m, error, solid, dynamic); }
            &-error-outline { @include  btn(label, m, error, outline, dynamic); }
            &-error-clear { @include  btn(label, m, error, clear, dynamic); }
            &-error-opacity { @include  btn(label, m, error, opacity, dynamic); }

            &-warn-solid { @include  btn(label, m, warn, solid, dynamic); }
            &-warn-outline { @include  btn(label, m, warn, outline, dynamic); }
            &-warn-clear { @include  btn(label, m, warn, clear, dynamic); }
            &-warn-opacity { @include  btn(label, m, warn, opacity, dynamic); }

            &-success-solid { @include  btn(label, m, success, solid, dynamic); }
            &-success-outline { @include  btn(label, m, success, outline, dynamic); }
            &-success-clear { @include  btn(label, m, success, clear, dynamic); }
            &-success-opacity { @include  btn(label, m, success, opacity, dynamic); }

            &-info-solid { @include  btn(label, m, info, solid, dynamic); }
            &-info-outline { @include  btn(label, m, info, outline, dynamic); }
            &-info-clear { @include  btn(label, m, info, clear, dynamic); }
            &-info-opacity { @include  btn(label, m, info, opacity, dynamic); }

            &-white-solid { @include  btn(label, m, white, solid, dynamic); }
            &-white-outline { @include  btn(label, m, white, outline, dynamic); }
            &-white-clear { @include  btn(label, m, white, clear, dynamic); }
            &-white-opacity { @include  btn(label, m, white, opacity, dynamic); }
        }
        &-l {
            &-default-solid { @include  btn(label, l, accent, solid, dynamic); }
            &-default-outline { @include  btn(label, l, accent, outline, dynamic); }
            &-default-clear { @include  btn(label, l, accent, clear, dynamic); }
            &-default-opacity { @include  btn(label, l, accent, opacity, dynamic); }

            &-globalcaja-solid { @include  btn(label, l, accent, solid, dynamic); }
            &-globalcaja-outline { @include  btn(label, l, accent, outline, dynamic); }
            &-globalcaja-clear { @include  btn(label, l, accent, clear, dynamic); }
            &-globalcaja-opacity { @include  btn(label, l, accent, opacity, dynamic); }

            &-accent-solid { @include  btn(label, l, accent, solid, dynamic); }
            &-accent-outline { @include  btn(label, l, accent, outline, dynamic); }
            &-accent-clear { @include  btn(label, l, accent, clear, dynamic); }
            &-accent-opacity { @include  btn(label, l, accent, opacity, dynamic); }

            &-error-solid { @include  btn(label, l, error, solid, dynamic); }
            &-error-outline { @include  btn(label, l, error, outline, dynamic); }
            &-error-clear { @include  btn(label, l, error, clear, dynamic); }
            &-error-opacity { @include  btn(label, l, error, opacity, dynamic); }

            &-warn-solid { @include  btn(label, l, warn, solid, dynamic); }
            &-warn-outline { @include  btn(label, l, warn, outline, dynamic); }
            &-warn-clear { @include  btn(label, l, warn, clear, dynamic); }
            &-warn-opacity { @include  btn(label, l, warn, opacity, dynamic); }

            &-success-solid { @include  btn(label, l, success, solid, dynamic); }
            &-success-outline { @include  btn(label, l, success, outline, dynamic); }
            &-success-clear { @include  btn(label, l, success, clear, dynamic); }
            &-success-opacity { @include  btn(label, l, success, opacity, dynamic); }

            &-info-solid { @include  btn(label, l, info, solid, dynamic); }
            &-info-outline { @include  btn(label, l, info, outline, dynamic); }
            &-info-clear { @include  btn(label, l, info, clear, dynamic); }
            &-info-opacity { @include  btn(label, l, info, opacity, dynamic); }

            &-white-solid { @include  btn(label, l, white, solid, dynamic); }
            &-white-outline { @include  btn(label, l, white, outline, dynamic); }
            &-white-clear { @include  btn(label, l, white, clear, dynamic); }
            &-white-opacity { @include  btn(label, l, white, opacity, dynamic); }
        }
    }

    .btn-icon {
        &-s {
            &-default-solid { @include  btn(icon, s, accent, solid, dynamic); }
            &-default-outline { @include  btn(icon, s, accent, outline, dynamic); }
            &-default-clear { @include  btn(icon, s, accent, clear, dynamic); }

            &-accent-solid { @include  btn(icon, s, accent, solid, dynamic); }
            &-accent-outline { @include  btn(icon, s, accent, outline, dynamic); }
            &-accent-clear { @include  btn(icon, s, accent, clear, dynamic); }

            &-error-solid { @include  btn(icon, s, error, solid, dynamic); }
            &-error-outline { @include  btn(icon, s, error, outline, dynamic); }
            &-error-clear { @include  btn(icon, s, error, clear, dynamic); }

            &-warn-solid { @include  btn(icon, s, warn, solid, dynamic); }
            &-warn-outline { @include  btn(icon, s, warn, outline, dynamic); }
            &-warn-clear { @include  btn(icon, s, warn, clear, dynamic); }
        }
        &, &-m {
            &-default-solid { @include  btn(icon, m, accent, solid, dynamic); }
            &-default-outline { @include  btn(icon, m, accent, outline, dynamic); }
            &-default-clear { @include  btn(icon, m, accent, clear, dynamic); }

            &-accent-solid { @include  btn(icon, m, accent, solid, dynamic); }
            &-accent-outline { @include  btn(icon, m, accent, outline, dynamic); }
            &-accent-clear { @include  btn(icon, m, accent, clear, dynamic); }

            &-error-solid { @include  btn(icon, m, error, solid, dynamic); }
            &-error-outline { @include  btn(icon, m, error, outline, dynamic); }
            &-error-clear { @include  btn(icon, m, error, clear, dynamic); }

            &-warn-solid { @include  btn(icon, m, warn, solid, dynamic); }
            &-warn-outline { @include  btn(icon, m, warn, outline, dynamic); }
            &-warn-clear { @include  btn(icon, m, warn, clear, dynamic); }
        }
        &-l {
            &-default-solid { @include  btn(icon, l, accent, solid, dynamic); }
            &-default-outline { @include  btn(icon, l, accent, outline, dynamic); }
            &-default-clear { @include  btn(icon, l, accent, clear, dynamic); }

            &-accent-solid { @include  btn(icon, l, accent, solid, dynamic); }
            &-accent-outline { @include  btn(icon, l, accent, outline, dynamic); }
            &-accent-clear { @include  btn(icon, l, accent, clear, dynamic); }

            &-error-solid { @include  btn(icon, l, error, solid, dynamic); }
            &-error-outline { @include  btn(icon, l, error, outline, dynamic); }
            &-error-clear { @include  btn(icon, l, error, clear, dynamic); }

            &-warn-solid { @include  btn(icon, l, warn, solid, dynamic); }
            &-warn-outline { @include  btn(icon, l, warn, outline, dynamic); }
            &-warn-clear { @include  btn(icon, l, warn, clear, dynamic); }
        }
    }
}
