/**
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root body.globalcoffee.dark{
  --gc-color-layout-body: rgb(26 19 15);
  --gc-color-layout-content: rgb(45 33 26);
  --gc-color-layout-text: rgb(237 228 221);
  --gc-color-step-25: rgb(30 24 21);
  --gc-color-step-50: rgb(37 29 25);
  --gc-color-step-100: rgb(47 40 36);
  --gc-color-step-150: rgb(58 50 46);
  --gc-color-step-200: rgb(68 61 56);
  --gc-color-step-250: rgb(79 71 67);
  --gc-color-step-300: rgb(89 82 77);
  --gc-color-step-350: rgb(100 92 87);
  --gc-color-step-400: rgb(110 103 97);
  --gc-color-step-450: rgb(121 113 108);
  --gc-color-step-500: rgb(132 124 118);
  --gc-color-step-550: rgb(142 134 128);
  --gc-color-step-600: rgb(153 144 139);
  --gc-color-step-650: rgb(163 155 149);
  --gc-color-step-700: rgb(174 165 159);
  --gc-color-step-750: rgb(184 176 170);
  --gc-color-step-800: rgb(195 186 180);
  --gc-color-step-850: rgb(205 197 190);
  --gc-color-step-900: rgb(216 207 200);
  --gc-color-step-950: rgb(226 218 211);
  --gc-color-primary-default: rgb(54 35 23);
  --gc-color-primary-default-contrast: rgb(238 251 245);
  --gc-color-primary-shade: rgb(27 18 12);
  --gc-color-primary-shade-contrast: rgb(238 251 245);
  --gc-color-primary-tone: rgb(91 81 75);
  --gc-color-primary-tone-contrast: rgb(238 251 245);
  --gc-color-primary-tint: rgb(154 145 139);
  --gc-color-primary-tint-contrast: rgb(42 28 19);
  --gc-color-primary-opacity-10: rgb(54 35 23 / 10%);
  --gc-color-primary-opacity-10-contrast: rgb(154 145 139 / 10%);
  --gc-color-secondary-default: rgb(148 93 56);
  --gc-color-secondary-default-contrast: rgb(238 251 245);
  --gc-color-secondary-shade: rgb(74 47 28);
  --gc-color-secondary-shade-contrast: rgb(245 248 247);
  --gc-color-secondary-tone: rgb(138 110 92);
  --gc-color-secondary-tone-contrast: rgb(245 248 247);
  --gc-color-secondary-tint: rgb(201 174 155);
  --gc-color-secondary-tint-contrast: rgb(74 47 28);
  --gc-color-secondary-opacity-10: rgb(148 93 56 / 10%);
  --gc-color-secondary-opacity-10-contrast: rgb(74 47 28 / 10%);
  --gc-color-accent-default: rgb(199 122 107);
  --gc-color-accent-default-contrast: rgb(238 251 244);
  --gc-color-success-default: rgb(44 175 114);
  --gc-color-success-default-contrast: rgb(255 255 255);
  --gc-color-success-shade: rgb(22 88 57);
  --gc-color-success-shade-contrast: rgb(255 255 255);
  --gc-color-success-tone: rgb(86 151 121);
  --gc-color-accent-shade: rgb(100 61 54);
  --gc-color-success-tone-contrast: rgb(255 255 255);
  --gc-color-success-tint: rgb(149 215 184);
  --gc-color-success-tint-contrast: rgb(22 88 57);
  --gc-color-success-opacity-10: rgb(44 175 114 / 10%);
  --gc-color-success-opacity-10-contrast: rgb(22 88 57 / 10%);
  --gc-color-accent-shade-contrast: rgb(238 251 244);
  --gc-color-accent-tone: rgb(163 125 117);
  --gc-color-accent-tone-contrast: rgb(238 251 244);
  --gc-color-accent-tint: rgb(227 188 181);
  --gc-color-accent-tint-contrast: rgb(66 40 36);
  --gc-color-accent-opacity-10: rgb(199 122 107 / 10%);
  --gc-color-accent-opacity-10-contrast: rgb(66 40 36 / 10%);
  --gc-color-accent-opacity-25: rgb(199 122 107 / 25%);
  --gc-color-accent-opacity-25-contrast: rgb(66 40 36 / 25%);
  --gc-color-warn-default: rgb(242 173 56);
  --gc-color-error-default: rgb(231 66 59);
  --gc-color-error-default-contrast: rgb(255 255 255);
  --gc-color-error-shade: rgb(116 33 30);
  --gc-color-error-shade-contrast: rgb(255 255 255);
  --gc-color-error-tint: rgb(243 160 157);
  --gc-color-error-tint-contrast: rgb(81 23 21);
  --gc-color-error-tone: rgb(179 97 93);
  --gc-color-error-tone-contrast: rgb(255 255 255);
  --gc-color-error-opacity-10: rgb(231 66 59 / 10%);
  --gc-color-error-opacity-10-contrast: rgb(81 23 21 / 10%);
  --gc-color-warn-default-contrast: rgb(255 255 255);
  --gc-color-warn-shade: rgb(121 87 28);
  --gc-color-warn-shade-contrast: rgb(255 255 255);
  --gc-color-warn-tone: rgb(185 150 92);
  --gc-color-warn-tone-contrast: rgb(255 255 255);
  --gc-color-warn-tint: rgb(249 214 155);
  --gc-color-warn-tint-contrast: rgb(76 75 75);
  --gc-color-warn-opacity-10: rgb(242 173 56 / 10%);
  --gc-color-warn-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-info-default: rgb(92 160 255);
  --gc-color-red-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-info-default-contrast: rgb(255 255 255);
  --gc-color-green-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-green-opacity-10: rgb(50 179 108 / 10%);
  --gc-color-green-tone-contrast: rgb(255 255 255);
  --gc-color-green-tone: rgb(24 131 86);
  --gc-color-green-tint-contrast: rgb(30 72 50);
  --gc-color-green-tint: rgb(126 215 170);
  --gc-color-green-shade-contrast: rgb(255 255 255);
  --gc-color-green-shade: rgb(18 83 58);
  --gc-color-green-default-contrast: rgb(51 51 51);
  --gc-color-green-default: rgb(50 179 108);
  --gc-color-red-opacity-10: rgb(255 60 80 / 10%);
  --gc-color-red-tone-contrast: rgb(255 255 255);
  --gc-color-red-tone: rgb(182 26 46);
  --gc-color-red-tint-contrast: rgb(72 30 30);
  --gc-color-red-tint: rgb(249 168 168);
  --gc-color-red-shade-contrast: rgb(255 255 255);
  --gc-color-red-shade: rgb(131 24 44);
  --gc-color-red-default-contrast: rgb(255 255 255);
  --gc-color-red-default: rgb(255 123 137);
  --gc-color-info-shade: rgb(46 80 128);
  --gc-color-info-shade-contrast: rgb(255 255 255);
  --gc-color-info-tint: rgb(173 207 255);
  --gc-color-info-tint-contrast: rgb(30 56 72);
  --gc-color-info-tone: rgb(110 144 191);
  --gc-color-info-tone-contrast: rgb(255 255 255);
  --gc-color-info-opacity-10: rgb(92 160 255 / 10%);
  --gc-color-info-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-blue-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-blue-opacity-10: rgb(94 157 185 / 10%);
  --gc-color-blue-tone-contrast: rgb(255 255 255);
  --gc-color-blue-tone: rgb(34 180 244);
  --gc-color-blue-tint-contrast: rgb(30 48 72);
  --gc-color-blue-tint: rgb(166 199 234);
  --gc-color-blue-shade-contrast: rgb(255 255 255);
  --gc-color-blue-shade: rgb(43 77 93);
  --gc-color-blue-default-contrast: rgb(255 255 255);
  --gc-color-blue-default: rgb(94 157 185);
  --gc-color-yellow-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-pink-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-purple-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-purple-opacity-10: rgb(126 89 160 / 10%);
  --gc-color-purple-tone-contrast: rgb(255 255 255);
  --gc-color-purple-tone: rgb(182 130 230);
  --gc-color-purple-tint-contrast: rgb(51 30 72);
  --gc-color-purple-tint: rgb(185 158 212);
  --gc-color-purple-shade-contrast: rgb(255 255 255);
  --gc-color-purple-shade: rgb(74 53 90);
  --gc-color-purple-default-contrast: rgb(255 255 255);
  --gc-color-purple-default: rgb(126 89 160);
  --gc-color-pink-opacity-10: rgb(245 57 139 / 10%);
  --gc-color-pink-tone-contrast: rgb(255 255 255);
  --gc-color-pink-tone: rgb(209 5 121);
  --gc-color-pink-tint-contrast: rgb(72 30 52);
  --gc-color-pink-tint: rgb(255 159 210);
  --gc-color-pink-shade-contrast: rgb(255 255 255);
  --gc-color-pink-shade: rgb(143 12 69);
  --gc-color-pink-default-contrast: rgb(255 255 255);
  --gc-color-pink-default: rgb(245 57 139);
  --gc-color-yellow-opacity-10: rgb(243 195 63 / 10%);
  --gc-color-yellow-tone-contrast: rgb(255 255 255);
  --gc-color-yellow-tone: rgb(235 155 27);
  --gc-color-yellow-tint-contrast: rgb(72 63 30);
  --gc-color-yellow-tint: rgb(248 227 152);
  --gc-color-yellow-shade-contrast: rgb(255 255 255);
  --gc-color-yellow-shade: rgb(152 93 16);
  --gc-color-yellow-default-contrast: rgb(51 51 51);
  --gc-color-yellow-default: rgb(255 186 61);
  --gc-color-brown-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-brown-opacity-10: rgb(165 79 73 / 10%);
  --gc-color-brown-tone-contrast: rgb(255 255 255);
  --gc-color-brown-tone: rgb(121 61 57);
  --gc-color-brown-tint-contrast: rgb(72 34 30);
  --gc-color-brown-tint: rgb(211 127 121);
  --gc-color-brown-shade-contrast: rgb(255 255 255);
  --gc-color-brown-shade: rgb(58 29 33);
  --gc-color-brown-default-contrast: rgb(255 255 255);
  --gc-color-brown-default: rgb(165 79 73);
  --gc-color-orange-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-orange-opacity-10: rgb(245 133 63 / 10%);
  --gc-color-orange-tone-contrast: rgb(255 255 255);
  --gc-color-orange-tone: rgb(188 83 18);
  --gc-color-orange-tint-contrast: rgb(72 49 30);
  --gc-color-orange-tint: rgb(249 185 128);
  --gc-color-orange-shade-contrast: rgb(255 255 255);
  --gc-color-orange-shade: rgb(121 58 21);
  --gc-color-orange-default-contrast: rgb(241 241 241);
  --gc-color-orange-default: rgb(245 133 63);
  --gc-color-black-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-black-opacity-10: rgb(40 40 40 / 10%);
  --gc-color-black-tone-contrast: rgb(255 255 255);
  --gc-color-black-tone: rgb(51 51 51);
  --gc-color-black-tint-contrast: rgb(255 255 255);
  --gc-color-black-tint: rgb(82 82 82);
  --gc-color-black-shade-contrast: rgb(255 255 255);
  --gc-color-black-shade: rgb(26 26 26);
  --gc-color-black-default-contrast: rgb(255 255 255);
  --gc-color-black-default: rgb(40 40 40);
  --gc-color-white-opacity-10-contrast: rgb(40 40 40 / 10%);
  --gc-color-white-opacity-10: rgb(241 241 241 / 10%);
  --gc-color-white-tone-contrast: rgb(51 51 51);
  --gc-color-white-tone: rgb(192 192 192);
  --gc-color-white-tint-contrast: rgb(82 82 82);
  --gc-color-white-tint: rgb(255 255 255);
  --gc-color-white-shade-contrast: rgb(26 26 26);
  --gc-color-white-shade: rgb(152 152 152);
  --gc-color-white-default-contrast: rgb(40 40 40);
  --gc-color-white-default: rgb(241 241 241);
  --gc-color-transparent: rgb(72 88 57 / 0%);
  --gc-color-skin-tone-contrast: rgb(64 39 42);
  --gc-color-skin-tone: rgb(178 128 135);
  --gc-color-skin-tint-contrast: rgb(89 13 15);
  --gc-color-skin-tint: rgb(248 211 212);
  --gc-color-skin-shade-contrast: rgb(51 31 34);
  --gc-color-skin-shade: rgb(92 61 66);
  --gc-color-skin-default-contrast: rgb(70 10 12);
  --gc-color-skin-default: rgb(247 186 188);
  --gc-color-impar: var(--gc-color-step-25);
  --gc-color-par: var(--gc-color-step-50);
}
