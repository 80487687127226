/**
 * Este archivo contiene los tokens relativos a los tamaños de iconos
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root.plt-desktop {
  --gc-size-icon-min-default: var(--gc-size-icon-min-m);
  --gc-size-icon-min-xxs: 6px;
  --gc-size-icon-min-xs: 10px;
  --gc-size-icon-min-s: 14px;
  --gc-size-icon-min-m: 20px;
  --gc-size-icon-min-l: 26px;
  --gc-size-icon-min-xl: 34px;
  --gc-size-icon-min-xxl: 38px;
  --gc-size-icon-min-xxxl: 40px;

  --gc-size-icon-default: var(--gc-size-icon-m); /* 22px */
  --gc-size-icon-xxs: 0.5rem; /* 8px */
  --gc-size-icon-xs: 0.75rem; /* 12px */
  --gc-size-icon-s: 1rem; /* 16px */
  --gc-size-icon-m: 1.375rem; /* 22px */
  --gc-size-icon-l: 1.75rem; /* 28px */
  --gc-size-icon-xl: 2.25rem; /* 36px */
  --gc-size-icon-xxl: 2.5rem; /* 40px */
  --gc-size-icon-xxxl: 2.625rem; /* 42px */


  --gc-size-icon-max-default: var(--gc-size-icon-max-m);
  --gc-size-icon-max-xxs: 12px;
  --gc-size-icon-max-xs: 16px;
  --gc-size-icon-max-s: 20px;
  --gc-size-icon-max-m: 26px;
  --gc-size-icon-max-l: 32px;
  --gc-size-icon-max-xl: 40px;
  --gc-size-icon-max-xxl: 44px;
  --gc-size-icon-max-xxxl: 46px;

  --gc-size-icon-stroke-m: 40px;
  --gc-size-icon-stroke-l: 52px;
 }

 :root.plt-mobile {
  --gc-size-icon-min-default: var(--gc-size-icon-min-m);
  --gc-size-icon-min-xxs: 6px;
  --gc-size-icon-min-xs: 10px;
  --gc-size-icon-min-s: 14px;
  --gc-size-icon-min-m: 20px;
  --gc-size-icon-min-l: 26px;
  --gc-size-icon-min-xl: 34px;
  --gc-size-icon-min-xxl: 38px;
  --gc-size-icon-min-xxxl: 40px;

  --gc-size-icon-default: var(--gc-size-icon-m); /* 22px */
  --gc-size-icon-xxs: 0.5rem; /* 8px */
  --gc-size-icon-xs: 0.75rem; /* 12px */
  --gc-size-icon-s: 1rem; /* 16px */
  --gc-size-icon-m: 1.375rem; /* 22px */
  --gc-size-icon-l: 1.75rem; /* 28px */
  --gc-size-icon-xl: 2.25rem; /* 36px */
  --gc-size-icon-xxl: 2.5rem; /* 40px */
  --gc-size-icon-xxxl: 2.625rem; /* 42px */


  --gc-size-icon-max-default: var(--gc-size-icon-max-m);
  --gc-size-icon-max-xxs: 12px;
  --gc-size-icon-max-xs: 16px;
  --gc-size-icon-max-s: 20px;
  --gc-size-icon-max-m: 26px;
  --gc-size-icon-max-l: 32px;
  --gc-size-icon-max-xl: 40px;
  --gc-size-icon-max-xxl: 44px;
  --gc-size-icon-max-xxxl: 46px;

  --gc-size-icon-stroke-m: 40px;
  --gc-size-icon-stroke-l: 52px;
}
