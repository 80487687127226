/**
 * Por favor, NO editar este archivo salvo previa consulta.
 */

:root body.rose.dark{
  --gc-color-layout-body: rgba(18, 23, 33, 1);
  --gc-color-layout-content: rgba(31, 36, 46, 1);
  --gc-color-layout-text: rgba(248, 250, 252, 1);
  --gc-color-step-25: rgba(25, 28, 36, 1);
  --gc-color-step-50: rgba(30, 34, 44, 1);
  --gc-color-step-100: rgba(41, 46, 55, 1);
  --gc-color-step-150: rgba(52, 57, 66, 1);
  --gc-color-step-200: rgba(64, 68, 77, 1);
  --gc-color-step-250: rgba(76, 80, 88, 1);
  --gc-color-step-300: rgba(87, 91, 99, 1);
  --gc-color-step-350: rgba(99, 102, 110, 1);
  --gc-color-step-400: rgba(110, 114, 121, 1);
  --gc-color-step-450: rgba(122, 125, 132, 1);
  --gc-color-step-500: rgba(133, 137, 143, 1);
  --gc-color-step-550: rgba(145, 148, 153, 1);
  --gc-color-step-600: rgba(156, 159, 164, 1);
  --gc-color-step-650: rgba(168, 171, 175, 1);
  --gc-color-step-700: rgba(179, 182, 186, 1);
  --gc-color-step-750: rgba(191, 193, 197, 1);
  --gc-color-step-800: rgba(202, 205, 208, 1);
  --gc-color-step-850: rgba(213, 216, 219, 1);
  --gc-color-step-900: rgba(225, 227, 230, 1);
  --gc-color-step-950: rgba(237, 239, 241, 1);
  --gc-color-primary-default: rgba(21, 28, 40, 1);
  --gc-color-primary-default-contrast: rgba(238, 251, 245, 1);
  --gc-color-primary-shade: rgba(11, 14, 20, 1);
  --gc-color-primary-shade-contrast: rgba(238, 251, 245, 1);
  --gc-color-primary-tint: rgba(138, 141, 147, 1);
  --gc-color-primary-tint-contrast: rgba(238, 251, 245, 1);
  --gc-color-primary-tone: rgba(74, 78, 84, 1);
  --gc-color-primary-tone-contrast: rgba(238, 251, 245, 1);
  --gc-color-primary-opacity-10: rgba(21, 28, 40, 0.1);
  --gc-color-secondary-default: rgba(80, 138, 149, 1);
  --gc-color-secondary-default-contrast: rgba(255, 254, 249, 1);
  --gc-color-secondary-shade: rgba(40, 69, 75, 1);
  --gc-color-secondary-shade-contrast: rgba(245, 248, 247, 1);
  --gc-color-secondary-tint: rgba(167, 197, 202, 1);
  --gc-color-secondary-tint-contrast: rgba(40, 69, 75, 1);
  --gc-color-secondary-tone: rgba(104, 133, 138, 1);
  --gc-color-secondary-tone-contrast: rgba(255, 254, 249, 1);
  --gc-color-secondary-opacity-10: rgba(80, 138, 149, 0.1);
  --gc-color-secondary-opacity-10-contrast: rgba(238, 251, 245, 0.1);
  --gc-color-primary-opacity-10-contrast: rgba(238, 251, 245, 0.1);
  --gc-color-accent-default: rgba(189, 117, 173, 1);
  --gc-color-accent-default-contrast: rgba(254, 254, 254, 1);
  --gc-color-accent-tint: rgba(222, 186, 214, 1);
  --gc-color-accent-tint-contrast: rgba(238, 212, 232, 1);
  --gc-color-accent-tone: rgba(158, 122, 150, 1);
  --gc-color-accent-tone-contrast: rgba(240, 208, 233, 1);
  --gc-color-accent-shade: rgba(95, 59, 87, 1);
  --gc-color-accent-shade-contrast: rgba(238, 213, 233, 1);
  --gc-color-accent-opacity-10: rgba(189, 117, 173, 0.1);
  --gc-color-accent-opacity-10-contrast: rgba(238, 225, 213, 0.1);
  --gc-color-success-default: rgba(46, 184, 52, 1);
  --gc-color-success-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-success-tint: rgba(150, 219, 153, 1);
  --gc-color-success-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-success-tone: rgba(74, 143, 77, 1);
  --gc-color-success-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-success-shade: rgba(23, 92, 26, 1);
  --gc-color-success-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-success-opacity-10: rgba(46, 184, 52, 0.1);
  --gc-color-success-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-warn-default: rgba(255, 167, 38, 1);
  --gc-color-warn-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-tint: rgba(255, 211, 146, 1);
  --gc-color-warn-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-tone: rgba(178, 134, 70, 1);
  --gc-color-warn-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-shade: rgba(128, 84, 19, 1);
  --gc-color-warn-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-opacity-10: rgba(255, 167, 38, 0.1);
  --gc-color-warn-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-error-default: rgba(244, 67, 54, 1);
  --gc-color-error-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-error-tint: rgba(249, 161, 154, 1);
  --gc-color-error-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-error-tone: rgba(173, 85, 78, 1);
  --gc-color-error-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-error-shade: rgba(145, 17, 8, 1);
  --gc-color-error-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-error-opacity-10: rgba(244, 67, 54, 0.1);
  --gc-color-error-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-info-default: rgba(66, 165, 245, 1);
  --gc-color-info-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-info-tint: rgba(160, 210, 250, 1);
  --gc-color-info-tint-contrast: rgba(18, 33, 42, 1);
  --gc-color-info-tone: rgba(84, 133, 173, 1);
  --gc-color-info-tone-contrast: rgba(254, 254, 255, 1);
  --gc-color-info-shade: rgba(33, 83, 123, 1);
  --gc-color-info-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-info-opacity-10: rgba(66, 165, 245, 0.1);
  --gc-color-info-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-red-default: rgba(255, 123, 137, 1);
  --gc-color-red-default-contrast: rgba(254, 254, 254, 1);
  --gc-color-red-tint: rgba(255, 135, 147, 1);
  --gc-color-red-tint-contrast: rgba(254, 254, 254, 1);
  --gc-color-red-tone: rgba(182, 26, 26, 1);
  --gc-color-red-tone-contrast: rgba(254, 254, 254, 1);
  --gc-color-red-shade: rgba(110, 25, 12, 1);
  --gc-color-red-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-green-default: rgba(98, 159, 20, 1);
  --gc-color-green-default-contrast: rgba(222, 243, 205, 1);
  --gc-color-green-tint: rgba(134, 205, 77, 1);
  --gc-color-green-tint-contrast: rgba(1, 1, 1, 1);
  --gc-color-green-tone: rgba(89, 139, 25, 1);
  --gc-color-green-tone-contrast: rgba(0, 0.1, 0.1, 1);
  --gc-color-green-shade: rgba(76, 120, 20, 1);
  --gc-color-green-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-blue-default: rgba(94, 157, 185, 1);
  --gc-color-blue-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-blue-tint: rgba(110, 167, 192, 1);
  --gc-color-blue-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-blue-tone: rgba(34, 180, 244, 1);
  --gc-color-blue-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-blue-shade: rgba(43, 77, 93, 1);
  --gc-color-blue-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-yellow-default: rgba(204, 159, 0.1, 1);
  --gc-color-yellow-default-contrast: rgba(0, 0.1, 0.1, 1);
  --gc-color-yellow-tint: rgba(255, 216, 98, 1);
  --gc-color-yellow-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-yellow-tone: rgba(212, 169, 9, 1);
  --gc-color-yellow-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-yellow-shade: rgba(170, 123, 0.1, 1);
  --gc-color-yellow-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-pink-default: rgba(197, 113, 138, 1);
  --gc-color-pink-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-pink-tint: rgba(216, 139, 157, 1);
  --gc-color-pink-tint-contrast: rgba(72, 30, 52, 1);
  --gc-color-pink-tone: rgba(197, 116, 138, 1);
  --gc-color-pink-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-pink-shade: rgba(166, 87, 115, 1);
  --gc-color-pink-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-purple-default: rgba(169, 122, 176, 1);
  --gc-color-purple-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-purple-tint: rgba(122, 90, 126, 1);
  --gc-color-purple-tint-contrast: rgba(51, 30, 72, 1);
  --gc-color-purple-tone: rgba(98, 55, 104, 1);
  --gc-color-purple-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-purple-shade: rgba(79, 52, 83, 1);
  --gc-color-purple-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-orange-default: rgba(209, 135, 0.1, 1);
  --gc-color-orange-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-orange-tint: rgba(255, 198, 99, 1);
  --gc-color-orange-tint-contrast: rgba(72, 49, 30, 1);
  --gc-color-orange-tone: rgba(188, 83, 18, 1);
  --gc-color-orange-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-orange-shade: rgba(163, 104, 0.1, 1);
  --gc-color-orange-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-brown-default: rgba(132, 71, 51, 1);
  --gc-color-brown-default-contrast: rgba(255, 254, 249, 1);
  --gc-color-brown-tint: rgba(175, 129, 113, 1);
  --gc-color-brown-tint-contrast: rgba(72, 34, 30, 1);
  --gc-color-brown-tone: rgba(133, 90, 76, 1);
  --gc-color-brown-tone-contrast: rgba(255, 254, 249, 1);
  --gc-color-brown-shade: rgba(70, 38, 26, 1);
  --gc-color-brown-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-default: rgb(40 40 40);
  --gc-color-black-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-tint: rgba(82, 82, 82, 1);
  --gc-color-black-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-tone: rgba(51, 51, 51, 1);
  --gc-color-black-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-shade: rgba(26, 26, 26, 1);
  --gc-color-black-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-red-opacity-10: rgba(255, 60, 80, 0.1);
  --gc-color-red-opacity-10-contrast: rgba(254, 254, 254, 0.1);
  --gc-color-green-opacity-10: rgba(98, 159, 20, 0.1);
  --gc-color-green-opacity-10-contrast: rgba(220, 235, 207, 0.1);
  --gc-color-blue-opacity-10: rgba(94, 157, 185, 0.1);
  --gc-color-blue-opacity-10-contrast: rgba(35, 35, 35, 0.1);
  --gc-color-yellow-opacity-10: rgba(204, 159, 0.1, 0.1);
  --gc-color-yellow-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-pink-opacity-10: rgba(197, 113, 138, 0.1);
  --gc-color-pink-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-purple-opacity-10: rgba(107, 72, 112, 0.1);
  --gc-color-purple-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-brown-opacity-10: rgba(132, 71, 51, 0.1);
  --gc-color-brown-opacity-10-contrast: rgba(255, 254, 249, 0.1);
  --gc-color-orange-opacity-10: rgba(255, 165, 0.1, 0.1);
  --gc-color-orange-opacity-10-contrast: rgba(51, 51, 51, 0.1);
  --gc-color-black-opacity-10: rgba(40, 40, 40, 0.1);
  --gc-color-black-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-white-default: rgba(255, 255, 255, 1);
  --gc-color-white-default-contrast: rgba(40, 40, 40, 1);
  --gc-color-white-tint: rgba(255, 255, 255, 1);
  --gc-color-white-tint-contrast: rgba(82, 82, 82, 1);
  --gc-color-white-tone: rgba(255, 255, 255, 1);
  --gc-color-white-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-white-shade: rgba(255, 255, 255, 1);
  --gc-color-white-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-white-opacity-10: rgba(255, 255, 255, 0.1);
  --gc-color-white-opacity-10-contrast: rgba(40, 40, 40, 0.1);
  --gc-color-accent-opacity-25: rgba(189, 117, 173, 0.1);
  --gc-color-accent-opacity-25-contrast: rgba(238, 225, 213, 0.1);
  --gc-color-skin-default: rgba(247, 186, 188, 1);
  --gc-color-skin-default-contrast: rgba(70, 10, 12, 1);
  --gc-color-skin-tint: rgba(248, 211, 212, 1);
  --gc-color-skin-tint-contrast: rgba(89, 13, 15, 1);
  --gc-color-skin-tone: rgba(184, 122, 131, 1);
  --gc-color-skin-tone-contrast: rgba(64, 39, 42, 1);
  --gc-color-skin-shade: rgba(92, 61, 66, 1);
  --gc-color-skin-shade-contrast: rgba(49, 33, 35, 1);
  --gc-color-transparent: rgba(21, 44, 38, 0.1);
  --gc-color-impar: var(--gc-color-step-25);
  --gc-color-par: var(--gc-color-step-50);
}
