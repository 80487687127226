/**
  * Genera los estilos del toggle
  */
@mixin toggle() {
    //Tokens relativos al tamaño de del track y handle del toogle
    --gc-size-toggle-track-height: 22.5px;
    --gc-size-toggle-track-width: 40px;
    --gc-size-toggle-handle-left: 2px;
    --gc-size-toggle-handle-top: 1px;
    --gc-size-toggle-handle-width: 18px;
    --gc-size-toggle-handle-height: var(--gc-size-toggle-handle-width);

    --handle-width: var(--gc-size-toggle-handle-width);
    --handle-height: var(--gc-size-toggle-handle-height);
    --handle-max-height: auto;
    --handle-border-radius: var(--gc-size-border-toggle-handle-radius);
    --handle-box-shadow: none;
  
  &::part(track) {
    height: var(--gc-size-toggle-track-height);
    width: var(--gc-size-toggle-track-width);
    border-radius:  var(--gc-size-border-radius-xs);
    background: var(--gc-color-step-50);
    border: var(--gc-size-border-toggle-track-width) solid  var(--gc-color-step-200);

    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }

  &::part(handle) {
    background: var(--gc-color-layout-background);
    border: 1px solid var(--gc-color-step-300);
    left: var(--gc-size-toggle-handle-left);
    top: var(--gc-size-toggle-handle-top);
  }

  &.toggle-checked {
    &::part(track) {
        background: var(--gc-color-accent-tint);
    }
    &::part(handle) {
        border: 1px solid var(--gc-color-accent-default);
        transform: translate3d( calc( 2.5px * -2 ), 0, 0 );
    }
  }

}
