/**
 * Este archivo contiene los tokens relativos a los tamaños de margen
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root.plt-desktop {

  --gc-size-margin-default: 16px;
  --gc-size-margin-cero: 0px;
  --gc-size-margin-xxs: 2px;
  --gc-size-margin-xs: 4px;
  --gc-size-margin-s: 8px;
  --gc-size-margin-m: 12px;
  --gc-size-margin-l: 16px;
  --gc-size-margin-xl: 24px;
  --gc-size-margin-xxl: 32px;
  --gc-size-margin-xxxl: 48px;

 }

 :root.plt-mobile {

  --gc-size-margin-default: 16px;
  --gc-size-margin-cero: 0px;
  --gc-size-margin-xxs: 2px;
  --gc-size-margin-xs: 4px;
  --gc-size-margin-s: 8px;
  --gc-size-margin-m: 12px;
  --gc-size-margin-l: 16px;
  --gc-size-margin-xl: 24px;
  --gc-size-margin-xxl: 32px;
  --gc-size-margin-xxxl: 48px;
}
