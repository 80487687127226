/**
 * Por favor, NO editar este archivo salvo previa consulta.
 */

:root body.rose.light{
  --gc-color-layout-body: rgba(244, 246, 250, 1);
  --gc-color-layout-content: rgba(251, 252, 253, 1);
  --gc-color-layout-text: rgba(4, 6, 8, 1);
  --gc-color-step-25: rgba(237, 239, 242, 1);
  --gc-color-step-50: rgba(232, 234, 238, 1);
  --gc-color-step-100: rgba(220, 222, 226, 1);
  --gc-color-step-150: rgba(208, 210, 214, 1);
  --gc-color-step-200: rgba(196, 198, 202, 1);
  --gc-color-step-250: rgba(184, 186, 190, 1);
  --gc-color-step-300: rgba(172, 174, 177, 1);
  --gc-color-step-350: rgba(160, 162, 165, 1);
  --gc-color-step-400: rgba(148, 150, 153, 1);
  --gc-color-step-450: rgba(136, 138, 141, 1);
  --gc-color-step-500: rgba(124, 126, 129, 1);
  --gc-color-step-550: rgba(112, 114, 117, 1);
  --gc-color-step-600: rgba(100, 102, 105, 1);
  --gc-color-step-650: rgba(88, 90, 93, 1);
  --gc-color-step-700: rgba(76, 78, 81, 1);
  --gc-color-step-750: rgba(64, 66, 69, 1);
  --gc-color-step-800: rgba(52, 54, 56, 1);
  --gc-color-step-850: rgba(40, 42, 44, 1);
  --gc-color-step-900: rgba(28, 30, 32, 1);
  --gc-color-step-950: rgba(16, 18, 20, 1);
  --gc-color-primary-default: rgba(36, 46, 66, 1);
  --gc-color-primary-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-primary-shade: rgba(18, 23, 33, 1);
  --gc-color-primary-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-primary-tint: rgba(145, 150, 160, 1);
  --gc-color-primary-tint-contrast: rgba(18, 23, 33, 1);
  --gc-color-primary-tone: rgba(82, 87, 97, 1);
  --gc-color-primary-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-primary-opacity-10: rgba(36, 46, 66, 0.1);
  --gc-color-secondary-default: rgba(55, 149, 149, 1);
  --gc-color-secondary-default-contrast: rgba(255, 254, 249, 1);
  --gc-color-secondary-shade: rgba(28, 75, 75, 1);
  --gc-color-secondary-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-secondary-tint: rgba(155, 202, 202, 1);
  --gc-color-secondary-tint-contrast: rgba(28, 75, 75, 1);
  --gc-color-secondary-tone: rgba(91, 138, 138, 1);
  --gc-color-secondary-tone-contrast: rgba(255, 254, 249, 1);
  --gc-color-secondary-opacity-10: rgba(55, 149, 149, 0.1);
  --gc-color-secondary-opacity-10-contrast: rgba(238, 251, 245, 0.1);
  --gc-color-primary-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-accent-default: rgba(165, 80, 144, 1);
  --gc-color-accent-default-contrast: rgba(254, 254, 254, 1);
  --gc-color-accent-tint: rgba(197, 160, 188, 1);
  --gc-color-accent-tint-contrast: rgb(39, 32, 38);
  --gc-color-accent-tone: rgba(133, 97, 125, 1);
  --gc-color-accent-tone-contrast: rgba(238, 213, 233, 1);
  --gc-color-accent-shade: rgba(55, 37, 51, 1);
  --gc-color-accent-shade-contrast: rgba(238, 213, 233, 1);
  --gc-color-accent-opacity-10: rgba(138, 66, 122, 0.1);
  --gc-color-accent-opacity-10-contrast: rgba(254, 254, 254, 0.1);
  --gc-color-success-default: rgba(21, 193, 50, 1);
  --gc-color-success-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-success-tint: rgba(138, 224, 152, 1);
  --gc-color-success-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-success-tone: rgba(74, 160, 89, 1);
  --gc-color-success-tone-contrast: rgba(26, 44, 27, 1);
  --gc-color-success-shade: rgba(11, 97, 25, 1);
  --gc-color-success-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-success-opacity-10: rgba(21, 193, 50, 0.1);
  --gc-color-success-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-warn-default: rgba(242, 158, 13, 1);
  --gc-color-warn-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-tint: rgba(249, 207, 134, 1);
  --gc-color-warn-tint-contrast: rgba(39, 36, 2, 1);
  --gc-color-warn-tone: rgba(185, 143, 70, 1);
  --gc-color-warn-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-shade: rgba(121, 79, 7, 1);
  --gc-color-warn-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-warn-opacity-10: rgba(242, 158, 13, 0.1);
  --gc-color-warn-opacity-10-contrast: rgba(42, 42, 42, 0.1);
  --gc-color-error-default: rgba(242, 13, 16, 1);
  --gc-color-error-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-error-tint: rgba(249, 134, 135, 1);
  --gc-color-error-tint-contrast: rgba(51, 26, 26, 1);
  --gc-color-error-tone: rgba(185, 70, 72, 1);
  --gc-color-error-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-error-shade: rgba(121, 7, 8, 1);
  --gc-color-error-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-error-opacity-10: rgba(242, 13, 16, 0.1);
  --gc-color-error-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-info-default: rgba(13, 166, 242, 1);
  --gc-color-info-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-info-tint: rgba(134, 211, 249, 1);
  --gc-color-info-tint-contrast: rgba(3, 40, 58, 1);
  --gc-color-info-tone: rgba(70, 147, 185, 1);
  --gc-color-info-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-info-shade: rgba(7, 83, 121, 1);
  --gc-color-info-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-info-opacity-10: rgba(13, 166, 242, 0.1);
  --gc-color-info-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-red-default: rgba(227, 26, 22, 1);
  --gc-color-red-default-contrast: rgba(254, 254, 254, 1);
  --gc-color-red-tint: rgba(197, 86, 84, 1);
  --gc-color-red-tint-contrast: rgba(0, 0.1, 0.1, 1);
  --gc-color-red-tone: rgba(182, 26, 26, 1);
  --gc-color-red-tone-contrast: rgba(254, 254, 254, 1);
  --gc-color-red-shade: rgba(127, 13, 12, 1);
  --gc-color-red-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-green-default: rgba(98, 159, 20, 1);
  --gc-color-green-default-contrast: rgba(220, 235, 207, 1);
  --gc-color-green-tint: rgba(134, 205, 77, 1);
  --gc-color-green-tint-contrast: rgba(1, 1, 1, 1);
  --gc-color-green-tone: rgba(89, 139, 25, 1);
  --gc-color-green-tone-contrast: rgba(0, 0.1, 0.1, 1);
  --gc-color-green-shade: rgba(76, 120, 20, 1);
  --gc-color-green-shade-contrast: rgba(254, 254, 254, 1);
  --gc-color-blue-default: rgba(94, 157, 185, 1);
  --gc-color-blue-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-blue-tint: rgba(110, 167, 192, 1);
  --gc-color-blue-tint-contrast: rgba(28, 41, 51, 1);
  --gc-color-blue-tone: rgba(72, 132, 158, 1);
  --gc-color-blue-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-blue-shade: rgba(43, 77, 93, 1);
  --gc-color-blue-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-yellow-default: rgba(255, 203, 8, 1);
  --gc-color-yellow-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-yellow-tint: rgba(255, 220, 71, 1);
  --gc-color-yellow-tint-contrast: rgba(39, 36, 2, 1);
  --gc-color-yellow-tone: rgba(212, 169, 9, 1);
  --gc-color-yellow-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-yellow-shade: rgba(212, 164, 6, 1);
  --gc-color-yellow-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-pink-default: rgba(233, 138, 164, 1);
  --gc-color-pink-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-pink-tint: rgba(243, 183, 199, 1);
  --gc-color-pink-tint-contrast: rgba(72, 30, 52, 1);
  --gc-color-pink-tone: rgba(197, 116, 138, 1);
  --gc-color-pink-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-pink-shade: rgba(199, 81, 114, 1);
  --gc-color-pink-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-purple-default: rgba(107, 72, 112, 1);
  --gc-color-purple-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-purple-tint: rgba(122, 90, 126, 1);
  --gc-color-purple-tint-contrast: rgba(51, 30, 72, 1);
  --gc-color-purple-tone: rgba(98, 55, 104, 1);
  --gc-color-purple-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-purple-shade: rgba(79, 52, 83, 1);
  --gc-color-purple-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-orange-default: rgba(255, 165, 0.1, 1);
  --gc-color-orange-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-orange-tint: rgba(255, 190, 88, 1);
  --gc-color-orange-tint-contrast: rgba(72, 49, 30, 1);
  --gc-color-orange-tone: rgba(188, 83, 18, 1);
  --gc-color-orange-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-orange-shade: rgba(204, 132, 0.1, 1);
  --gc-color-orange-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-brown-default: rgba(132, 71, 51, 1);
  --gc-color-brown-default-contrast: rgba(255, 254, 249, 1);
  --gc-color-brown-tint: rgba(175, 129, 113, 1);
  --gc-color-brown-tint-contrast: rgba(72, 34, 30, 1);
  --gc-color-brown-tone: rgba(133, 90, 76, 1);
  --gc-color-brown-tone-contrast: rgba(255, 254, 249, 1);
  --gc-color-brown-shade: rgba(70, 38, 26, 1);
  --gc-color-brown-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-default: rgba(40, 40, 40, 1);
  --gc-color-black-default-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-tint: rgba(82, 82, 82, 1);
  --gc-color-black-tint-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-tone: rgba(51, 51, 51, 1);
  --gc-color-black-tone-contrast: rgba(255, 255, 255, 1);
  --gc-color-black-shade: rgba(26, 26, 26, 1);
  --gc-color-black-shade-contrast: rgba(255, 255, 255, 1);
  --gc-color-red-opacity-10: rgba(227, 26, 22, 0.1);
  --gc-color-red-opacity-10-contrast: rgba(254, 254, 254, 0.1);
  --gc-color-green-opacity-10: rgba(98, 159, 20, 0.1);
  --gc-color-green-opacity-10-contrast: rgba(220, 235, 207, 0.1);
  --gc-color-blue-opacity-10: rgba(94, 157, 185, 0.1);
  --gc-color-blue-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-yellow-opacity-10: rgba(255, 203, 8, 0.1);
  --gc-color-yellow-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-pink-opacity-10: rgba(233, 138, 164, 0.1);
  --gc-color-pink-opacity-10-contrast: rgba(25, 24, 24, 0.1);
  --gc-color-purple-opacity-10: rgba(107, 72, 112, 0.1);
  --gc-color-purple-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-brown-opacity-10: rgba(132, 71, 51, 0.1);
  --gc-color-brown-opacity-10-contrast: rgba(255, 254, 249, 0.1);
  --gc-color-orange-opacity-10: rgba(255, 165, 0.1, 0.1);
  --gc-color-orange-opacity-10-contrast: rgba(51, 51, 51, 0.1);
  --gc-color-black-opacity-10: rgba(40, 40, 40, 0.1);
  --gc-color-black-opacity-10-contrast: rgba(255, 255, 255, 0.1);
  --gc-color-white-default: rgba(241, 241, 241, 1);
  --gc-color-white-default-contrast: rgba(40, 40, 40, 1);
  --gc-color-white-tint: rgba(255, 255, 255, 1);
  --gc-color-white-tint-contrast: rgba(82, 82, 82, 1);
  --gc-color-white-tone: rgba(192, 192, 192, 1);
  --gc-color-white-tone-contrast: rgba(51, 51, 51, 1);
  --gc-color-white-shade: rgba(152, 152, 152, 1);
  --gc-color-white-shade-contrast: rgba(26, 26, 26, 1);
  --gc-color-white-opacity-10: rgba(241, 241, 241, 0.1);
  --gc-color-white-opacity-10-contrast: rgba(40, 40, 40, 0.1);
  --gc-color-accent-opacity-25: rgba(138, 66, 122, 0.25);
  --gc-color-accent-opacity-25-contrast: rgba(254, 254, 254, 0.25);
  --gc-color-skin-default: rgba(241, 161, 164, 1);
  --gc-color-skin-default-contrast: rgba(70, 10, 12, 1);
  --gc-color-skin-tint: rgba(247, 202, 203, 1);
  --gc-color-skin-tint-contrast: rgba(89, 13, 15, 1);
  --gc-color-skin-tone: rgba(178, 128, 135, 1);
  --gc-color-skin-tone-contrast: rgba(64, 39, 42, 1);
  --gc-color-skin-shade: rgba(160, 97, 106, 1);
  --gc-color-skin-shade-contrast: rgba(51, 31, 34, 1);
  --gc-color-transparent: rgba(21, 44, 38, 0);
  --gc-color-impar: var(--gc-color-step-25);
  --gc-color-par: var(--gc-color-step-50);
}
