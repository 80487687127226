/**
 * Este archivo contiene los tokens relativos a los tamaños de gap
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root.plt-desktop {
  --gc-size-gap-default: 16px;
  --gc-size-gap-cero: 0px;
  --gc-size-gap-xxs: 2px;
  --gc-size-gap-xs: 4px;
  --gc-size-gap-s: 8px;
  --gc-size-gap-m: 16px;
  --gc-size-gap-l: 24px;
  --gc-size-gap-xl: 32px;
  --gc-size-gap-xxl: 48px;
 }

 :root.plt-mobile {
  --gc-size-gap-default: 16px;
  --gc-size-gap-cero: 0px;
  --gc-size-gap-xxs: 2px;
  --gc-size-gap-xs: 4px;
  --gc-size-gap-s: 8px;
  --gc-size-gap-m: 16px;
  --gc-size-gap-l: 24px;
  --gc-size-gap-xl: 32px;
  --gc-size-gap-xxl: 48px;
}
