/**
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root body.globalcampo.dark {
  --gc-color-layout-body: rgb(24 37 31);
  --gc-color-layout-content: rgb(28 43 36);
  --gc-color-layout-text: rgb(234 246 243);
  --gc-color-step-25: rgb(30 41 37);
  --gc-color-step-50: rgb(35 47 42);
  --gc-color-step-100: rgb(45 58 52);
  --gc-color-step-150: rgb(56 68 63);
  --gc-color-step-200: rgb(66 79 73);
  --gc-color-step-250: rgb(77 89 84);
  --gc-color-step-300: rgb(87 100 95);
  --gc-color-step-350: rgb(98 110 105);
  --gc-color-step-400: rgb(108 121 116);
  --gc-color-step-450: rgb(119 131 126);
  --gc-color-step-500: rgb(129 142 137);
  --gc-color-step-550: rgb(140 152 148);
  --gc-color-step-600: rgb(150 162 158);
  --gc-color-step-650: rgb(161 173 169);
  --gc-color-step-700: rgb(171 183 179);
  --gc-color-step-750: rgb(182 194 190);
  --gc-color-step-800: rgb(192 204 201);
  --gc-color-step-850: rgb(203 215 211);
  --gc-color-step-900: rgb(213 225 222);
  --gc-color-step-950: rgb(223 236 232);
  --gc-color-primary-default: rgb(6 55 44);
  --gc-color-primary-default-contrast: rgb(238 251 245);
  --gc-color-primary-shade: rgb(3 28 22);
  --gc-color-primary-shade-contrast: rgb(238 251 245);
  --gc-color-primary-tint: rgb(130 155 149);
  --gc-color-primary-tint-contrast: rgb(45 57 54);
  --gc-color-primary-tone: rgb(67 91 86);
  --gc-color-primary-tone-contrast: rgb(238 251 245);
  --gc-color-primary-opacity-10: rgb(6 55 44 / 10%);
  --gc-color-secondary-default: rgb(61 143 129);
  --gc-color-secondary-default-contrast: rgb(238 251 245);
  --gc-color-secondary-shade: rgb(31 72 65);
  --gc-color-secondary-shade-contrast: rgb(245 248 247);
  --gc-color-secondary-tint: rgb(158 199 192);
  --gc-color-secondary-tint-contrast: rgb(31 72 65);
  --gc-color-secondary-tone: rgb(94 135 128);
  --gc-color-secondary-tone-contrast: rgb(245 248 247);
  --gc-color-secondary-opacity-10: rgb(61 143 129 / 10%);
  --gc-color-transparent: rgb(21 44 38 / 0%);
  --gc-color-impar: var(--gc-color-step-25);
  --gc-color-par: var(--gc-color-step-50);
  --gc-color-secondary-opacity-10-contrast: rgb(31 72 65 / 10%);
  --gc-color-primary-opacity-10-contrast: rgb(117 240 212 / 10%);
  --gc-color-accent-default: rgb(45 185 122);
  --gc-color-accent-default-contrast: rgb(238 251 244);
  --gc-color-accent-shade: rgb(22 90 59);
  --gc-color-accent-shade-contrast: rgb(238 251 244);
  --gc-color-accent-tint: rgb(150 220 188);
  --gc-color-accent-tint-contrast: rgb(38 79 59);
  --gc-color-accent-tone: rgb(86 156 125);
  --gc-color-accent-tone-contrast: rgb(238 251 244);
  --gc-color-accent-opacity-10: rgb(45 185 122 / 10%);
  --gc-color-accent-opacity-10-contrast: rgb(150 220 188 / 10%);
  --gc-color-success-default: rgb(87 214 116);
  --gc-color-success-default-contrast: rgb(255 255 255);
  --gc-color-success-tint: rgb(171 235 185);
  --gc-color-success-tint-contrast: rgb(30 71 53);
  --gc-color-success-tone: rgb(107 171 122);
  --gc-color-success-tone-contrast: rgb(255 255 255);
  --gc-color-success-shade: rgb(44 107 58);
  --gc-color-success-shade-contrast: rgb(255 255 255);
  --gc-color-success-opacity-10: rgb(87 214 116 / 10%);
  --gc-color-success-opacity-10-contrast: rgb(13 33 25 / 10%);
  --gc-color-warn-default: rgb(255 178 66);
  --gc-color-warn-default-contrast: rgb(255 255 255);
  --gc-color-warn-shade: rgb(128 89 33);
  --gc-color-warn-shade-contrast: rgb(255 255 255);
  --gc-color-warn-tone: rgb(191 153 97);
  --gc-color-warn-tone-contrast: rgb(255 255 255);
  --gc-color-warn-tint: rgb(255 217 160);
  --gc-color-warn-tint-contrast: rgb(255 255 255);
  --gc-color-warn-opacity-10: rgb(255 178 66 / 10%);
  --gc-color-warn-opacity-10-contrast: rgb(128 84 19 / 10%);
  --gc-color-error-default: rgb(199 35 52);
  --gc-color-error-default-contrast: rgb(255 255 255);
  --gc-color-error-tint: rgb(227 145 153);
  --gc-color-error-tint-contrast: rgb(72 30 38);
  --gc-color-error-tone: rgb(163 81 90);
  --gc-color-error-tone-contrast: rgb(255 255 255);
  --gc-color-error-shade: rgb(112 20 29);
  --gc-color-error-shade-contrast: rgb(255 255 255);
  --gc-color-error-opacity-10: rgb(199 35 52 / 10%);
  --gc-color-error-opacity-10-contrast: rgb(1 1 1 / 10%);
  --gc-color-info-default: rgb(69 210 232);
  --gc-color-info-default-contrast: rgb(255 255 255);
  --gc-color-info-tint: rgb(162 233 243);
  --gc-color-info-tint-contrast: rgb(30 67 72);
  --gc-color-info-tone: rgb(98 169 180);
  --gc-color-info-tone-contrast: rgb(255 255 255);
  --gc-color-info-shade: rgb(35 105 116);
  --gc-color-info-shade-contrast: rgb(255 255 255);
  --gc-color-info-opacity-10: rgb(69 210 232 / 10%);
  --gc-color-info-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-red-default: rgb(255 123 137);
  --gc-color-red-default-contrast: rgb(255 255 255);
  --gc-color-red-tint: rgb(249 168 168);
  --gc-color-red-tint-contrast: rgb(72 30 30);
  --gc-color-red-tone: rgb(182 26 46);
  --gc-color-red-tone-contrast: rgb(255 255 255);
  --gc-color-red-shade: rgb(131 24 44);
  --gc-color-red-shade-contrast: rgb(255 255 255);
  --gc-color-green-default: rgb(50 179 108);
  --gc-color-green-default-contrast: rgb(51 51 51);
  --gc-color-green-tint: rgb(126 215 170);
  --gc-color-green-tint-contrast: rgb(30 72 50);
  --gc-color-green-tone: rgb(24 131 86);
  --gc-color-green-tone-contrast: rgb(255 255 255);
  --gc-color-green-shade: rgb(18 83 58);
  --gc-color-green-shade-contrast: rgb(255 255 255);
  --gc-color-green-opacity-10: rgb(50 179 108 / 10%);
  --gc-color-green-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-blue-default: rgb(94 157 185);
  --gc-color-blue-default-contrast: rgb(255 255 255);
  --gc-color-blue-tint: rgb(166 199 234);
  --gc-color-blue-tint-contrast: rgb(30 48 72);
  --gc-color-blue-tone: rgb(34 180 244);
  --gc-color-blue-tone-contrast: rgb(255 255 255);
  --gc-color-blue-shade: rgb(43 77 93);
  --gc-color-blue-shade-contrast: rgb(255 255 255);
  --gc-color-yellow-default: rgb(255 186 61);
  --gc-color-yellow-default-contrast: rgb(51 51 51);
  --gc-color-yellow-tint: rgb(248 227 152);
  --gc-color-yellow-tint-contrast: rgb(72 63 30);
  --gc-color-yellow-tone: rgb(235 155 27);
  --gc-color-yellow-tone-contrast: rgb(255 255 255);
  --gc-color-yellow-shade: rgb(152 81 16);
  --gc-color-yellow-shade-contrast: rgb(255 255 255);
  --gc-color-pink-default: rgb(245 57 139);
  --gc-color-pink-default-contrast: rgb(255 255 255);
  --gc-color-pink-tint: rgb(255 159 210);
  --gc-color-pink-tint-contrast: rgb(72 30 52);
  --gc-color-pink-tone: rgb(209 5 121);
  --gc-color-pink-tone-contrast: rgb(255 255 255);
  --gc-color-pink-shade: rgb(143 12 69);
  --gc-color-pink-shade-contrast: rgb(255 255 255);
  --gc-color-purple-default: rgb(182 130 230);
  --gc-color-brown-default: rgb(165 79 73);
  --gc-color-brown-default-contrast: rgb(238 251 244);
  --gc-color-brown-tint: rgb(211 127 121);
  --gc-color-brown-tint-contrast: rgb(72 34 30);
  --gc-color-brown-tone: rgb(121 61 57);
  --gc-color-brown-tone-contrast: rgb(255 255 255);
  --gc-color-brown-shade: rgb(58 29 33);
  --gc-color-brown-shade-contrast: rgb(255 255 255);
  --gc-color-brown-opacity-10: rgb(165 79 73 / 10%);
  --gc-color-brown-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-purple-default-contrast: rgb(255 255 255);
  --gc-color-purple-tint: rgb(185 158 212);
  --gc-color-purple-tint-contrast: rgb(51 30 72);
  --gc-color-purple-tone: rgb(182 130 230);
  --gc-color-purple-tone-contrast: rgb(255 255 255);
  --gc-color-purple-shade: rgb(74 53 90);
  --gc-color-purple-shade-contrast: rgb(255 255 255);
  --gc-color-orange-default: rgb(245 133 63);
  --gc-color-orange-default-contrast: rgb(241 241 241);
  --gc-color-orange-tint: rgb(249 185 128);
  --gc-color-orange-tint-contrast: rgb(72 49 30);
  --gc-color-orange-tone: rgb(188 83 18);
  --gc-color-orange-tone-contrast: rgb(255 255 255);
  --gc-color-orange-shade: rgb(121 58 21);
  --gc-color-orange-shade-contrast: rgb(255 255 255);
  --gc-color-black-default: rgb(40 40 40);
  --gc-color-black-default-contrast: rgb(255 255 255);
  --gc-color-black-tint: rgb(183 183 183);
  --gc-color-black-tint-contrast: rgb(255 255 255);
  --gc-color-black-tone: rgb(51 51 51);
  --gc-color-black-tone-contrast: rgb(255 255 255);
  --gc-color-black-shade: rgb(26 26 26);
  --gc-color-black-shade-contrast: rgb(255 255 255);
  --gc-color-red-opacity-10: rgb(215 38 56 / 10%);
  --gc-color-red-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-blue-opacity-10: rgb(94 157 185 / 10%);
  --gc-color-blue-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-yellow-opacity-10: rgb(243 195 63 / 10%);
  --gc-color-yellow-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-pink-opacity-10: rgb(245 57 139 / 10%);
  --gc-color-pink-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-purple-opacity-10: rgb(126 89 160 / 10%);
  --gc-color-purple-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-orange-opacity-10: rgb(245 133 63 / 10%);
  --gc-color-orange-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-black-opacity-10: rgb(40 40 40 / 10%);
  --gc-color-black-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-white-default: rgb(247 247 247);
  --gc-color-white-default-contrast: rgb(40 40 40);
  --gc-color-white-tint: rgb(255 255 255);
  --gc-color-white-tint-contrast: rgb(82 82 82);
  --gc-color-white-tone: rgb(255 255 255);
  --gc-color-white-tone-contrast: rgb(51 51 51);
  --gc-color-white-shade: rgb(255 255 255);
  --gc-color-white-shade-contrast: rgb(26 26 26);
  --gc-color-white-opacity-10: rgb(247 247 247 / 10%);
  --gc-color-white-opacity-10-contrast: rgb(40 40 40 / 10%);
  --gc-color-accent-opacity-25: rgb(45 185 122 / 25%);
  --gc-color-accent-opacity-25-contrast: rgb(150 220 188 / 25%);
  --gc-color-skin-default: rgb(247 186 188);
  --gc-color-skin-default-contrast: rgb(70 10 12);
  --gc-color-skin-tint: rgb(248 211 212);
  --gc-color-skin-tint-contrast: rgb(89 13 15);
  --gc-color-skin-tone: rgb(178 128 135);
  --gc-color-skin-tone-contrast: rgb(64 39 42);
  --gc-color-skin-shade: rgb(92 61 66);
  --gc-color-skin-shade-contrast: rgb(51 31 34);
}
