/**
 * Este archivo contiene las clases relativas al popover
 * Por favor, NO editar este archivo salvo previa consulta.
 */

:root {
    #popoverActList, #popoverTrabajadores, #popoverUsers, #popoverNotificacions, #logsUsers, #popoverMaquinaria, #popoverNotificaciones {
        --max-width: 180px;
        &::part(content){
          border-radius: var(--gc-size-border-radius-xs);
          background: var(--gc-color-layout-body);
          padding: var(--gc-size-padding-xs);
        }

        ion-item{
          font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
          font-family: var(--gc-font-poppins-semibold);
          border-radius: var(--gc-size-border-radius-xs);
          background: transparent;
          .img {
            --gc-color-icon-fill: var(--gc-color-step-900);
              font-size: min(var(--gc-size-font-xl), var(--gc-size-font-max-xl));
              margin-right: var(--gc-size-margin-s);
          }

          &:hover{
              --background: var(--gc-color-step-50);
              .img{
                --gc-color-icon-fill: var(--gc-color-accent-default);
              }
          }
        }
    }
}
