/**
 * Este archivo contiene los tokens relativos a los media query
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root {

  --gc-media-query-xs: 22.5em; /* 360px */
  --gc-media-query-s: 36em; /* 576px */
  --gc-media-query-m: 48em; /* 768px */
  --gc-media-query-l: 62em; /* 992px */
  --gc-media-query-xl: 64.063em; /* 1025px */
  --gc-media-query-xxl: 75em; /* 1200px */
  --gc-media-query-xxxl: 106.205em; /* 1700px */

 }