/**
 * Este archivo contiene las clases relativas a los alert
 * Las clases usan los mixins presentes en mixins-alert.scss para construir la visual
 * Por favor, NO editar este archivo salvo previa consulta.
 */

@import "src/theme/design-system/mixins/mixins-alert.scss";

:root {
  #alert {
    &.default { @include alert(default, false); &.showIcon { @include alert(default, true) } }
    &.warn { @include alert(warn, false); &.showIcon { @include alert(warn, true) } }
    &.success { @include alert(success, false); &.showIcon { @include alert(success, true) } }
    &.error { @include alert(error, false); &.showIcon { @include alert(error, true) } }
    &.info { @include alert(info, false); &.showIcon { @include alert(info, true) } }
  }
  #gc-alert { @include alert-manager(); }
}
