/**
  * Genera los estilos del searchbar
  * @param $size (s, m o l) : hace referencia al tamaño del searchbar
  */
@mixin searchbar($size) {
    //Tokens relativos al tamaño de padding del input y el top de los iconos
    --gc-size-searchbar-input-padding-left:  48px;
    --gc-size-searchbar-input-padding-right: 48px;

    --gc-size-searchbar-input-padding-l-bottom: var(--gc-size-padding-s);
    --gc-size-searchbar-input-padding-l-top: var(--gc-size-padding-s);
    --gc-size-searchbar-input-padding-m-bottom: var( --gc-size-padding-xs);
    --gc-size-searchbar-input-padding-m-top: var( --gc-size-padding-xs);
    --gc-size-searchbar-input-padding-s-bottom: var(--gc-size-padding-cero);
    --gc-size-searchbar-input-padding-s-top: var(--gc-size-padding-cero);

    --gc-size-searchbar-icon-l-top: 12px;
    --gc-size-searchbar-icon-m-top: 8px;
    --gc-size-searchbar-icon-s-top: 4px;

    padding-top: var(--gc-size-padding-cero);
    padding-bottom: var(--gc-size-padding-cero);
    padding-inline-start: var(--gc-size-padding-cero);
    padding-inline-end: var(--gc-size-padding-cero);

    border: 2px solid var(--gc-color-step-200);
    border-radius: var(--gc-size-border-radius-s);
    --border-radius: var(--gc-size-border-radius-s);
    --box-shadow: none;

    --background: var(--gc-color-layout-body);
    --color: var(--gc-color-step-800);


    .searchbar-input {
        font-size: min(var(--gc-size-font-#{$size}), var(--gc-size-font-max-#{$size}));
        font-family: var(--gc-font-family-primary-medium);
        padding-top: var(--gc-size-searchbar-input-padding-#{$size}-top);
        padding-bottom: var(--gc-size-searchbar-input-padding-#{$size}-bottom);
        padding-inline-start: var(--gc-size-searchbar-input-padding-left);
        padding-inline-end:  var(--gc-size-searchbar-input-padding-right);
    }

    .searchbar-search-icon, .searchbar-clear-icon {
        color: var(--gc-color-step-400);
        top: var(--gc-size-searchbar-icon-#{$size}-top);
        width:  min(var(--gc-size-icon-#{$size}), var(--gc-size-icon-max-#{$size}));
        height:  min(var(--gc-size-icon-#{$size}), var(--gc-size-icon-max-#{$size}));
    }

    &.searchbar-has-focus{
    --background: var(--gc-color-layout-body);
    border: 2px solid var(--gc-color-accent-default);
        .searchbar-search-icon, .searchbar-clear-icon {
            color: var(--gc-color-accent-default);
        }
    }

}
