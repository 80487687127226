/**
 * Este archivo contiene los tokens relativos a los tamaños de fuente
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root.plt-desktop {

  // minimum value
  --gc-size-font-min-default: var(--gc-size-font-min-m);
  --gc-size-font-min-xxs: 10px;
  --gc-size-font-min-xs: 10px;
  --gc-size-font-min-s: 10px;
  --gc-size-font-min-m: 10px;
  --gc-size-font-min-l: 11px;
  --gc-size-font-min-xl: 13px;
  --gc-size-font-min-xxl: 15px;
  --gc-size-font-min-xxxl: 21px;
  --gc-size-font-min-xxxxl: 29px;

  // preferred value
  --gc-size-font-default: var(--gc-size-font-m);
  --gc-size-font-xxs: 0.625rem; /* 10px */
  --gc-size-font-xs: 0.688rem; /* 11px */
  --gc-size-font-s: 0.75rem; /* 12px */
  --gc-size-font-m: 0.813rem; /* 13px */
  --gc-size-font-l: 0.875rem; /* 14px */
  --gc-size-font-xl: 1rem; /* 16px */
  --gc-size-font-xxl: 1.125rem; /* 18px */
  --gc-size-font-xxxl: 1.5rem; /* 24px */
  --gc-size-font-xxxxl: 2rem; /* 32px */

  // maximum value
  --gc-size-font-max-default: var(--gc-size-font-max-m);
  --gc-size-font-max-xxs: 18px;
  --gc-size-font-max-xs: 19px;
  --gc-size-font-max-s: 20px;
  --gc-size-font-max-m: 21px;
  --gc-size-font-max-l: 22px;
  --gc-size-font-max-xl: 24px;
  --gc-size-font-max-xxl: 26px;
  --gc-size-font-max-xxxl: 30px;
  --gc-size-font-max-xxxxl: 40px;

 }

 :root.plt-mobile {

  // minimum value
  --gc-size-font-min-default: var(--gc-size-font-min-m);
  --gc-size-font-min-xxs: 10px;
  --gc-size-font-min-xs: 10px;
  --gc-size-font-min-s: 10px;
  --gc-size-font-min-m: 11px;
  --gc-size-font-min-l: 13px;
  --gc-size-font-min-xl: 15px;
  --gc-size-font-min-xxl: 19px;
  --gc-size-font-min-xxxl: 25px;
  --gc-size-font-min-xxxxl: 33px;

  // preferred value
  --gc-size-font-default: var(--gc-size-font-m);
  --gc-size-font-xxs: 0.688rem; /* 11px */
  --gc-size-font-xs: 0.75rem; /* 12px */
  --gc-size-font-s: 0.813rem; /* 13px */
  --gc-size-font-m: 0.875rem; /* 14px */
  --gc-size-font-l: 1rem; /* 16px */
  --gc-size-font-xl: 1.125rem; /* 18px */
  --gc-size-font-xxl: 1.375rem; /* 22px */
  --gc-size-font-xxxl: 1.75rem; /* 28px */
  --gc-size-font-xxxxl: 2.25rem; /* 36px */

  // maximum value
  --gc-size-font-max-default: var(--gc-size-font-max-m);
  --gc-size-font-max-xxs: 19px;
  --gc-size-font-max-xs: 20px;
  --gc-size-font-max-s: 21px;
  --gc-size-font-max-m: 22px;
  --gc-size-font-max-l: 24px;
  --gc-size-font-max-xl: 26px;
  --gc-size-font-max-xxl: 30px;
  --gc-size-font-max-xxxl: 36px;
  --gc-size-font-max-xxxxl: 44px;

}
