/**
  * Genera el código visual para el componente alert
  * @param $variant (accent, default, error, warn, success, info) : hace referencia al tipo de alert que queremos crear
  * @param $icon (boolean): hace referencia a si la alerta presenta un icono o no
  */
@mixin alert($variant, $icon) {

    --background: var(--gc-color-layout-body);
    --max-width: 300px;

    .alert-wrapper {
        --gc-color-default-default: var(--gc-color-accent-default);
        border-top: 8px solid var(--gc-color-#{$variant}-default);
        border-radius: var(--gc-size-border-radius-s);
        padding: var(--gc-size-padding-m);

        @if $icon == true {
            --gc-size-alert-icon-border-width: 6px;
            --gc-size-alert-icon: 48px;
            &::before {
                content: ' ';
                position: relative;
                width: var(--gc-size-alert-icon);
                height: var(--gc-size-alert-icon);
                margin:  var(--gc-size-margin-xs);
                z-index: 1;
                left: 50%;
                transform: translate(-50%);
                border-radius: var(--gc-size-alert-icon);
                border: var(--gc-size-alert-icon-border-width) solid var(--gc-color-#{$variant}-default);
                background-image: url('/assets/images/vectores/app-estado/#{$variant}.svg');
                background-position: center;
                background-size: 60%;
                @if $variant == default {
                    background-size: 80%;
                }
                background-repeat: no-repeat;
            }
        }

        .alert-head{
            padding-inline-start: var(--gc-size-padding-m);
            padding-inline-end: var(--gc-size-padding-m);
            padding-top: var(--gc-size-padding-m);
            padding-bottom: var(--gc-size-padding-m);

            :nth-child(1){
                font-size: min(var(--gc-size-font-xl), var(--gc-size-font-max-xl));
                font-family: var(--gc-font-family-primary-semibold);
                text-align: center;
                color: var(--gc-color-layout-text);
            }

            :nth-child(2){
                margin-top: var(--gc-size-margin-xs);
                font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
                font-family: var(--gc-font-family-primary-medium);
                text-align: center;
                color: var(--gc-color-step-600);
            }
        }

        .alert-message{
            padding-inline-start: var(--gc-size-padding-m);
            padding-inline-end: var(--gc-size-padding-m);
            padding-top: var(--gc-size-padding-cero);
            padding-bottom: var(--gc-size-padding-m);

            font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
            font-family: var(--gc-font-family-primary-medium);
            text-align: center;
            color: var(--gc-color-step-600);
        }

        .alert-button-group{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap-reverse;
            justify-content: center;
            align-items: center;
            gap: var(--gc-size-gap-s);

            padding-inline-start: var(--gc-size-padding-s);
            padding-inline-end: var(--gc-size-padding-s);
            padding-top: var(--gc-size-padding-s);
            padding-bottom: var(--gc-size-padding-s);

            button {
                height: auto;
            }
        }
    }
}


  /**
   * Mixin que genera el código visual para las alertas del servicio de alert-manager
   */
    @mixin alert-manager {
        --gc-size-min-width-alert: 300px;
        --gc-size-max-width-alert: 400px;
        --backdrop-opacity: 0.10;

    &::part(content){
        position: absolute;
        bottom: 0;
        height: fit-content;
        background: var(--gc-color-layout-body);
        border-radius: var(--gc-size-border-radius-l) var(--gc-size-border-radius-l) var(--gc-size-border-radius-cero) var(--gc-size-border-radius-cero);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    }

    @media screen and (min-width: 22.5em) {
        &::part(content){
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: fit-content;
        min-width: var(--gc-size-min-width-alert);
        max-width: var(--gc-size-max-width-alert);
        margin: var(--gc-size-margin-xl);
        border-radius: var(--gc-size-border-radius-m);
        }
    }
    }