/**
 * Por favor, NO editar este archivo salvo previa consulta.
 */

:root body.cyberpunk.dark{
  --gc-color-layout-body: rgb(4 16 38);
  --gc-color-layout-content: rgb(15 36 30);
  --gc-color-layout-text: rgb(51 255 201);
  --gc-color-step-25: rgb(5 22 36);
  --gc-color-step-50: rgb(6 28 46);
  --gc-color-step-100: rgb(9 40 54);
  --gc-color-step-150: rgb(11 52 62);
  --gc-color-step-200: rgb(13 64 71);
  --gc-color-step-250: rgb(16 76 79);
  --gc-color-step-300: rgb(18 88 87);
  --gc-color-step-350: rgb(20 100 95);
  --gc-color-step-400: rgb(23 112 103);
  --gc-color-step-450: rgb(25 124 111);
  --gc-color-step-500: rgb(28 136 120);
  --gc-color-step-550: rgb(30 147 128);
  --gc-color-step-600: rgb(32 159 136);
  --gc-color-step-650: rgb(35 171 144);
  --gc-color-step-700: rgb(37 183 152);
  --gc-color-step-750: rgb(39 195 160);
  --gc-color-step-800: rgb(42 207 168);
  --gc-color-step-850: rgb(44 219 177);
  --gc-color-step-900: rgb(46 231 185);
  --gc-color-step-950: rgb(49 243 193);
  --gc-color-primary-default: rgb(2 8 18);
  --gc-color-primary-default-contrast: rgb(255 255 255);
  --gc-color-primary-shade: rgb(1 4 9);
  --gc-color-primary-shade-contrast: rgb(238 251 245);
  --gc-color-primary-tint: rgb(128 131 136);
  --gc-color-primary-tint-contrast: rgb(238 251 245);
  --gc-color-primary-tone: rgb(65 68 73);
  --gc-color-primary-tone-contrast: rgb(238 251 245);
  --gc-color-primary-opacity-10: rgb(2 8 18 / 10%);
  --gc-color-secondary-default: rgb(0 153 112);
  --gc-color-secondary-default-contrast: rgb(255 254 249);
  --gc-color-secondary-shade: rgb(0 77 56);
  --gc-color-secondary-shade-contrast: rgb(245 248 247);
  --gc-color-secondary-tint: rgb(128 204 183);
  --gc-color-secondary-tint-contrast: rgb(0 77 56);
  --gc-color-secondary-tone: rgb(64 140 120);
  --gc-color-secondary-tone-contrast: rgb(255 254 249);
  --gc-color-secondary-opacity-10: rgb(0 153 112 / 10%);
  --gc-color-secondary-opacity-10-contrast: rgb(238 251 245 / 10%);
  --gc-color-primary-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-accent-default: rgb(196 160 248);
  --gc-color-accent-default-contrast: rgb(43 7 95);
  --gc-color-accent-tint: rgb(225 207 251);
  --gc-color-accent-tint-contrast: rgb(43 7 95);
  --gc-color-accent-tone: rgb(162 144 188);
  --gc-color-accent-tone-contrast: rgb(43 7 95);
  --gc-color-accent-shade: rgb(74 60 93);
  --gc-color-accent-shade-contrast: rgb(238 225 213);
  --gc-color-accent-opacity-10: rgb(196 160 248 / 10%);
  --gc-color-accent-opacity-10-contrast: rgb(43 7 95 / 10%);
  --gc-color-success-default: rgb(46 184 52);
  --gc-color-success-default-contrast: rgb(255 255 255);
  --gc-color-success-tint: rgb(150 219 153);
  --gc-color-success-tint-contrast: rgb(26, 44, 27);
  --gc-color-success-tone: rgb(74 143 77);
  --gc-color-success-tone-contrast: rgb(255 255 255);
  --gc-color-success-shade: rgb(23 92 26);
  --gc-color-success-shade-contrast: rgb(254 254 254);
  --gc-color-success-opacity-10: rgb(46 184 52 / 10%);
  --gc-color-success-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-warn-default: rgb(255 167 38);
  --gc-color-warn-default-contrast: rgb(255 255 255);
  --gc-color-warn-tint: rgb(255 211 146);
  --gc-color-warn-tint-contrast: rgb(39, 36, 2);
  --gc-color-warn-tone: rgb(178 134 70);
  --gc-color-warn-tone-contrast: rgb(255 255 255);
  --gc-color-warn-shade: rgb(128 84 19);
  --gc-color-warn-shade-contrast: rgb(255 255 255);
  --gc-color-warn-opacity-10: rgb(255 167 38 / 10%);
  --gc-color-warn-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-error-default: rgb(244 67 54);
  --gc-color-error-default-contrast: rgb(255 255 255);
  --gc-color-error-tint: rgb(249 161 154);
  --gc-color-error-tint-contrast: rgb(51, 26, 26);
  --gc-color-error-tone: rgb(173 85 78);
  --gc-color-error-tone-contrast: rgb(255 255 255);
  --gc-color-error-shade: rgb(145 17 8);
  --gc-color-error-shade-contrast: rgb(254 254 254);
  --gc-color-error-opacity-10: rgb(244 67 54 / 10%);
  --gc-color-error-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-info-default: rgb(66 165 245);
  --gc-color-info-default-contrast: rgb(255 255 255);
  --gc-color-info-tint: rgb(160 210 250);
  --gc-color-info-tint-contrast: rgb(18 33 42);
  --gc-color-info-tone: rgb(84 133 173);
  --gc-color-info-tone-contrast: rgb(254 254 255);
  --gc-color-info-shade: rgb(33 83 123);
  --gc-color-info-shade-contrast: rgb(255 255 255);
  --gc-color-info-opacity-10: rgb(66 165 245 / 10%);
  --gc-color-info-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-red-default: rgb(255 123 137);
  --gc-color-red-default-contrast: rgb(254 254 254);
  --gc-color-red-tint: rgb(255 135 147);
  --gc-color-red-tint-contrast: rgb(51, 26, 26);
  --gc-color-red-tone: rgb(182 26 26);
  --gc-color-red-tone-contrast: rgb(254 254 254);
  --gc-color-red-shade: rgb(110 25 12);
  --gc-color-red-shade-contrast: rgb(254 254 254);
  --gc-color-green-default: rgb(98 159 20);
  --gc-color-green-default-contrast: rgb(222 243 205);
  --gc-color-green-tint: rgb(134 205 77);
  --gc-color-green-tint-contrast: rgb(1 1 1);
  --gc-color-green-tone: rgb(89 139 25);
  --gc-color-green-tone-contrast: rgb(0 0 0);
  --gc-color-green-shade: rgb(76 120 20);
  --gc-color-green-shade-contrast: rgb(254 254 254);
  --gc-color-blue-default: rgb(94 157 185);
  --gc-color-blue-default-contrast: rgb(255 255 255);
  --gc-color-blue-tint: rgb(110 167 192);
  --gc-color-blue-tint-contrast: rgb(28, 41, 51);
  --gc-color-blue-tone: rgb(34 180 244);
  --gc-color-blue-tone-contrast: rgb(255 255 255);
  --gc-color-blue-shade: rgb(43 77 93);
  --gc-color-blue-shade-contrast: rgb(255 255 255);
  --gc-color-yellow-default: rgb(204 159 0);
  --gc-color-yellow-default-contrast: rgb(0 0 0);
  --gc-color-yellow-tint: rgb(255 216 98);
  --gc-color-yellow-tint-contrast: rgb(39, 36, 2);
  --gc-color-yellow-tone: rgb(212 169 9);
  --gc-color-yellow-tone-contrast: rgb(255 255 255);
  --gc-color-yellow-shade: rgb(170 123 0);
  --gc-color-yellow-shade-contrast: rgb(255 255 255);
  --gc-color-pink-default: rgb(197 113 138);
  --gc-color-pink-default-contrast: rgb(255 255 255);
  --gc-color-pink-tint: rgb(216 139 157);
  --gc-color-pink-tint-contrast: rgb(72 30 52);
  --gc-color-pink-tone: rgb(197 116 138);
  --gc-color-pink-tone-contrast: rgb(255 255 255);
  --gc-color-pink-shade: rgb(166 87 115);
  --gc-color-pink-shade-contrast: rgb(255 255 255);
  --gc-color-purple-default: rgb(169 122 176);
  --gc-color-purple-default-contrast: rgb(255 255 255);
  --gc-color-purple-tint: rgb(122 90 126);
  --gc-color-purple-tint-contrast: rgb(51 30 72);
  --gc-color-purple-tone: rgb(98 55 104);
  --gc-color-purple-tone-contrast: rgb(255 255 255);
  --gc-color-purple-shade: rgb(79 52 83);
  --gc-color-purple-shade-contrast: rgb(255 255 255);
  --gc-color-orange-default: rgb(209 135 0);
  --gc-color-orange-default-contrast: rgb(255 255 255);
  --gc-color-orange-tint: rgb(255 198 99);
  --gc-color-orange-tint-contrast: rgb(72 49 30);
  --gc-color-orange-tone: rgb(188 83 18);
  --gc-color-orange-tone-contrast: rgb(255 255 255);
  --gc-color-orange-shade: rgb(163 104 0);
  --gc-color-orange-shade-contrast: rgb(255 255 255);
  --gc-color-brown-default: rgb(132 71 51);
  --gc-color-brown-default-contrast: rgb(255 254 249);
  --gc-color-brown-tint: rgb(175 129 113);
  --gc-color-brown-tint-contrast: rgb(72 34 30);
  --gc-color-brown-tone: rgb(133 90 76);
  --gc-color-brown-tone-contrast: rgb(255 254 249);
  --gc-color-brown-shade: rgb(70 38 26);
  --gc-color-brown-shade-contrast: rgb(255 255 255);
  --gc-color-black-default: rgb(40 40 40);
  --gc-color-black-default-contrast: rgb(255 255 255);
  --gc-color-black-tint: rgb(82 82 82);
  --gc-color-black-tint-contrast: rgb(255 255 255);
  --gc-color-black-tone: rgb(51 51 51);
  --gc-color-black-tone-contrast: rgb(255 255 255);
  --gc-color-black-shade: rgb(26 26 26);
  --gc-color-black-shade-contrast: rgb(255 255 255);
  --gc-color-red-opacity-10: rgb(255 60 80 / 10%);
  --gc-color-red-opacity-10-contrast: rgb(254 254 254 / 10%);
  --gc-color-green-opacity-10: rgb(98 159 20 / 10%);
  --gc-color-green-opacity-10-contrast: rgb(220 235 207 / 10%);
  --gc-color-blue-opacity-10: rgb(94 157 185 / 10%);
  --gc-color-blue-opacity-10-contrast: rgb(35 35 35 / 10%);
  --gc-color-yellow-opacity-10: rgb(204 159 0 / 10%);
  --gc-color-yellow-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-pink-opacity-10: rgb(197 113 138 / 10%);
  --gc-color-pink-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-purple-opacity-10: rgb(107 72 112 / 10%);
  --gc-color-purple-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-brown-opacity-10: rgb(132 71 51 / 10%);
  --gc-color-brown-opacity-10-contrast: rgb(255 254 249 / 10%);
  --gc-color-orange-opacity-10: rgb(255 165 0 / 10%);
  --gc-color-orange-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-black-opacity-10: rgb(40 40 40 / 10%);
  --gc-color-black-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-white-default: rgb(255 255 255);
  --gc-color-white-default-contrast: rgb(40 40 40);
  --gc-color-white-tint: rgb(255 255 255);
  --gc-color-white-tint-contrast: rgb(82 82 82);
  --gc-color-white-tone: rgb(255 255 255);
  --gc-color-white-tone-contrast: rgb(255 255 255);
  --gc-color-white-shade: rgb(255 255 255);
  --gc-color-white-shade-contrast: rgb(255 255 255);
  --gc-color-white-opacity-10: rgb(255 255 255 / 10%);
  --gc-color-white-opacity-10-contrast: rgb(40 40 40 / 10%);
  --gc-color-accent-opacity-25: rgb(196 160 248 / 25%);
  --gc-color-accent-opacity-25-contrast: rgb(43 7 95 / 25%);
  --gc-color-skin-default: rgb(247 186 188);
  --gc-color-skin-default-contrast: rgb(70 10 12);
  --gc-color-skin-tint: rgb(248 211 212);
  --gc-color-skin-tint-contrast: rgb(89 13 15);
  --gc-color-skin-tone: rgb(184 122 131);
  --gc-color-skin-tone-contrast: rgb(64 39 42);
  --gc-color-skin-shade: rgb(92 61 66);
  --gc-color-skin-shade-contrast: rgb(49 33 35);
  --gc-color-transparent: rgb(21 44 38 / 0%);
  --gc-color-impar: var(--gc-color-step-25);
  --gc-color-par: var(--gc-color-step-50);
}
