/**
  * Generan safe areas a un elemento (necesario para vista mobile)
  */
@mixin safe-area-top() {
    padding-top: var(--ion-safe-area-top);
}
@mixin safe-area-bottom() {
    padding-bottom: var(--ion-safe-area-bottom);
}
@mixin safe-area-left() {
    padding-left: var(--ion-safe-area-left);
}
@mixin safe-area-right() {
    padding-right: var(--ion-safe-area-right);
}

/**
  * Genera padding a un elemento
  * @param $top (var size) : hace referencia al padding top del elemento
  * @param $right (var size) : hace referencia al padding right del elemento
  * @param $bottom (var size) : hace referencia al padding bottom del elemento
  * @param $left (var size) : hace referencia al padding left del elemento
  */
$padding: var(--gc-size-padding-xl); //var size por defecto
@mixin padding-content($top: $padding, $right: $padding, $bottom: $padding, $left: $padding) {
    padding: $top $right $bottom $left;
    --padding-start: #{$left};
    --padding-end: #{$right};
    --padding-top: #{$top};
    --padding-bottom: #{$bottom};
}

/**
  * Genera scrollbar a un elemento
  */
@mixin scrollbar() {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    scrollbar-color: var(--gc-color-accent-default) transparent;
    scrollbar-width: 8px;

    &::-webkit-scrollbar{
        width: 8px;
        background: var(--gc-color-accent-default);
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 8px;
        background: var(--gc-color-accent-default);
    }
}
