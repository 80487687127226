/**
 * Este archivo contiene los tokens relativos a los width y radius de los bordes
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 :root.plt-desktop {
   --gc-size-border-radius-default: var(--gc-size-border-radius-m);
   --gc-size-border-radius-cero: 0px;
   --gc-size-border-radius-xxs: 2px;
   --gc-size-border-radius-xs: 4px;
   --gc-size-border-radius-s: 8px;
   --gc-size-border-radius-m: 16px;
   --gc-size-border-radius-l: 24px;
   --gc-size-border-radius-xl: 32px;
   --gc-size-border-radius-xxl: 48px;
 }

 :root.plt-mobile {
   --gc-size-border-radius-default: var(--gc-size-border-radius-m);
   --gc-size-border-radius-cero: 0px;
   --gc-size-border-radius-xxs: 2px;
   --gc-size-border-radius-xs: 4px;
   --gc-size-border-radius-s: 8px;
   --gc-size-border-radius-m: 16px;
   --gc-size-border-radius-l: 24px;
   --gc-size-border-radius-xl: 32px;
   --gc-size-border-radius-xxl: 48px;
 }
