/**
 * Por favor, NO editar este archivo salvo previa consulta.
 */

:root body.ocean.light{
  --gc-color-step-50: rgb(239 240 241);
  --gc-color-layout-body: rgb(251 252 253);
  --gc-color-layout-content: rgb(251 252 253);
  --gc-color-layout-text: rgb(7 12 17);
  --gc-color-step-25: rgb(244 245 245);
  --gc-color-step-100: rgb(227 228 229);
  --gc-color-step-150: rgb(214 216 218);
  --gc-color-step-200: rgb(202 204 206);
  --gc-color-step-250: rgb(190 192 194);
  --gc-color-step-300: rgb(178 180 182);
  --gc-color-step-350: rgb(166 168 170);
  --gc-color-step-400: rgb(153 156 159);
  --gc-color-step-450: rgb(141 144 147);
  --gc-color-step-500: rgb(129 132 135);
  --gc-color-step-550: rgb(117 120 123);
  --gc-color-step-600: rgb(105 108 111);
  --gc-color-step-650: rgb(92 96 100);
  --gc-color-step-700: rgb(80 84 88);
  --gc-color-step-750: rgb(68 72 76);
  --gc-color-step-800: rgb(56 60 64);
  --gc-color-step-850: rgb(44 48 52);
  --gc-color-step-900: rgb(31 36 41);
  --gc-color-step-950: rgb(19 24 29);
  --gc-color-primary-default: rgb(1 58 99);
  --gc-color-primary-default-contrast: rgb(255 255 255);
  --gc-color-primary-shade: rgb(1 29 50);
  --gc-color-primary-shade-contrast: rgb(255 255 255);
  --gc-color-primary-tint: rgb(128 156 177);
  --gc-color-primary-tint-contrast: rgb(1 29 50);
  --gc-color-primary-tone: rgb(64 93 113);
  --gc-color-primary-tone-contrast: rgb(255 255 255);
  --gc-color-primary-opacity-10: rgb(1 58 99 / 10%);
  --gc-color-secondary-default: rgb(31 113 173);
  --gc-color-accent-default: rgb(247 108 94);
  --gc-color-accent-default-contrast: rgb(254 254 254);
  --gc-color-accent-tint: rgb(251 181 174);
  --gc-color-accent-tint-contrast: rgb(238 213 215);
  --gc-color-accent-tone: rgb(187 118 111);
  --gc-color-accent-tone-contrast: rgb(238 213 215);
  --gc-color-accent-shade: rgb(124 54 47);
  --gc-color-accent-shade-contrast: rgb(238 213 215);
  --gc-color-accent-opacity-10: rgb(247 108 94 / 10%);
  --gc-color-accent-opacity-10-contrast: rgb(254 254 254 / 10%);
  --gc-color-accent-opacity-25: rgb(247 108 94 / 25%);
  --gc-color-accent-opacity-25-contrast: rgb(254 254 254 / 25%);
  --gc-color-secondary-default-contrast: rgb(255 254 249);
  --gc-color-secondary-shade: rgb(16 57 87);
  --gc-color-secondary-shade-contrast: rgb(255 255 255);
  --gc-color-secondary-tint: rgb(143 184 214);
  --gc-color-secondary-tint-contrast: rgb(16 57 87);
  --gc-color-secondary-tone: rgb(79 120 150);
  --gc-color-secondary-tone-contrast: rgb(255 254 249);
  --gc-color-secondary-opacity-10: rgb(31 113 173 / 10%);
  --gc-color-secondary-opacity-10-contrast: rgb(238 251 245 / 10%);
  --gc-color-primary-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-success-default: rgb(40 180 99);
  --gc-color-success-default-contrast: rgb(255 255 255);
  --gc-color-success-tint: rgb(147 217 177);
  --gc-color-success-tint-contrast: rgb(255 255 255);
  --gc-color-success-tone: rgb(84 154 113);
  --gc-color-success-tone-contrast: rgb(255 255 255);
  --gc-color-success-shade: rgb(20 90 50);
  --gc-color-success-shade-contrast: rgb(254 254 254);
  --gc-color-success-opacity-10: rgb(40 180 99 / 10%);
  --gc-color-success-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-warn-default: rgb(255 159 67);
  --gc-color-warn-default-contrast: rgb(255 255 255);
  --gc-color-warn-tint: rgb(255 207 161);
  --gc-color-warn-tint-contrast: rgb(255 255 255);
  --gc-color-warn-tone: rgb(191 143 97);
  --gc-color-warn-tone-contrast: rgb(255 255 255);
  --gc-color-warn-shade: rgb(128 80 34);
  --gc-color-warn-shade-contrast: rgb(255 255 255);
  --gc-color-warn-opacity-10: rgb(255 159 67 / 10%);
  --gc-color-warn-opacity-10-contrast: rgb(42 42 42 / 10%);
  --gc-color-error-default: rgb(217 48 37);
  --gc-color-error-default-contrast: rgb(255 255 255);
  --gc-color-error-tint: rgb(236 151 146);
  --gc-color-error-tint-contrast: rgb(255 255 255);
  --gc-color-error-tone: rgb(172 88 82);
  --gc-color-error-tone-contrast: rgb(255 255 255);
  --gc-color-error-shade: rgb(109 24 19);
  --gc-color-error-shade-contrast: rgb(254 254 254);
  --gc-color-error-opacity-10: rgb(217 48 37 / 10%);
  --gc-color-error-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-info-default: rgb(51 102 204);
  --gc-color-info-default-contrast: rgb(255 255 255);
  --gc-color-info-tint: rgb(153 178 229);
  --gc-color-info-tint-contrast: rgb(26 51 102);
  --gc-color-info-tone: rgb(89 115 166);
  --gc-color-info-tone-contrast: rgb(255 255 255);
  --gc-color-info-shade: rgb(26 51 102);
  --gc-color-info-shade-contrast: rgb(255 255 255);
  --gc-color-info-opacity-10: rgb(51 102 204 / 10%);
  --gc-color-info-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-red-default: rgb(227 26 22);
  --gc-color-red-default-contrast: rgb(254 254 254);
  --gc-color-red-tint: rgb(197 86 84);
  --gc-color-red-tint-contrast: rgb(0 0 0);
  --gc-color-red-tone: rgb(182 26 26);
  --gc-color-red-tone-contrast: rgb(254 254 254);
  --gc-color-red-shade: rgb(127 13 12);
  --gc-color-red-shade-contrast: rgb(254 254 254);
  --gc-color-green-default: rgb(98 159 20);
  --gc-color-green-default-contrast: rgb(220 235 207);
  --gc-color-green-tint: rgb(134 205 77);
  --gc-color-green-tint-contrast: rgb(1 1 1);
  --gc-color-green-tone: rgb(89 139 25);
  --gc-color-green-tone-contrast: rgb(0 0 0);
  --gc-color-green-shade: rgb(76 120 20);
  --gc-color-green-shade-contrast: rgb(254 254 254);
  --gc-color-blue-default: rgb(94 157 185);
  --gc-color-blue-default-contrast: rgb(255 255 255);
  --gc-color-blue-tint: rgb(110 167 192);
  --gc-color-blue-tint-contrast: rgb(255 255 255);
  --gc-color-blue-tone: rgb(72 132 158);
  --gc-color-blue-tone-contrast: rgb(255 255 255);
  --gc-color-blue-shade: rgb(43 77 93);
  --gc-color-blue-shade-contrast: rgb(255 255 255);
  --gc-color-yellow-default: rgb(255 203 8);
  --gc-color-yellow-default-contrast: rgb(255 255 255);
  --gc-color-yellow-tint: rgb(255 220 71);
  --gc-color-yellow-tint-contrast: rgb(255 255 255);
  --gc-color-yellow-tone: rgb(212 169 9);
  --gc-color-yellow-tone-contrast: rgb(255 255 255);
  --gc-color-yellow-shade: rgb(212 164 6);
  --gc-color-yellow-shade-contrast: rgb(255 255 255);
  --gc-color-pink-default: rgb(233 138 164);
  --gc-color-pink-default-contrast: rgb(255 255 255);
  --gc-color-pink-tint: rgb(243 183 199);
  --gc-color-pink-tint-contrast: rgb(72 30 52);
  --gc-color-pink-tone: rgb(197 116 138);
  --gc-color-pink-tone-contrast: rgb(255 255 255);
  --gc-color-pink-shade: rgb(199 81 114);
  --gc-color-pink-shade-contrast: rgb(255 255 255);
  --gc-color-purple-default: rgb(107 72 112);
  --gc-color-purple-default-contrast: rgb(255 255 255);
  --gc-color-purple-tint: rgb(122 90 126);
  --gc-color-purple-tint-contrast: rgb(51 30 72);
  --gc-color-purple-tone: rgb(98 55 104);
  --gc-color-purple-tone-contrast: rgb(255 255 255);
  --gc-color-purple-shade: rgb(79 52 83);
  --gc-color-purple-shade-contrast: rgb(255 255 255);
  --gc-color-orange-default: rgb(255 165 0);
  --gc-color-orange-default-contrast: rgb(255 255 255);
  --gc-color-orange-tint: rgb(255 190 88);
  --gc-color-orange-tint-contrast: rgb(72 49 30);
  --gc-color-orange-tone: rgb(188 83 18);
  --gc-color-orange-tone-contrast: rgb(255 255 255);
  --gc-color-orange-shade: rgb(204 132 0);
  --gc-color-orange-shade-contrast: rgb(255 255 255);
  --gc-color-brown-default: rgb(132 71 51);
  --gc-color-brown-default-contrast: rgb(255 254 249);
  --gc-color-brown-tint: rgb(175 129 113);
  --gc-color-brown-tint-contrast: rgb(72 34 30);
  --gc-color-brown-tone: rgb(133 90 76);
  --gc-color-brown-tone-contrast: rgb(255 254 249);
  --gc-color-brown-shade: rgb(70 38 26);
  --gc-color-brown-shade-contrast: rgb(255 255 255);
  --gc-color-black-default: rgb(40 40 40);
  --gc-color-black-default-contrast: rgb(255 255 255);
  --gc-color-black-tint: rgb(82 82 82);
  --gc-color-black-tint-contrast: rgb(255 255 255);
  --gc-color-black-tone: rgb(51 51 51);
  --gc-color-black-tone-contrast: rgb(255 255 255);
  --gc-color-black-shade: rgb(26 26 26);
  --gc-color-black-shade-contrast: rgb(255 255 255);
  --gc-color-red-opacity-10: rgb(227 26 22 / 10%);
  --gc-color-red-opacity-10-contrast: rgb(254 254 254 / 10%);
  --gc-color-green-opacity-10: rgb(98 159 20 / 10%);
  --gc-color-green-opacity-10-contrast: rgb(220 235 207 / 10%);
  --gc-color-blue-opacity-10: rgb(94 157 185 / 10%);
  --gc-color-blue-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-yellow-opacity-10: rgb(255 203 8 / 10%);
  --gc-color-yellow-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-pink-opacity-10: rgb(233 138 164 / 10%);
  --gc-color-pink-opacity-10-contrast: rgb(25 24 24 / 10%);
  --gc-color-purple-opacity-10: rgb(107 72 112 / 10%);
  --gc-color-purple-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-brown-opacity-10: rgb(132 71 51 / 10%);
  --gc-color-brown-opacity-10-contrast: rgb(255 254 249 / 10%);
  --gc-color-orange-opacity-10: rgb(255 165 0 / 10%);
  --gc-color-orange-opacity-10-contrast: rgb(51 51 51 / 10%);
  --gc-color-black-opacity-10: rgb(40 40 40 / 10%);
  --gc-color-black-opacity-10-contrast: rgb(255 255 255 / 10%);
  --gc-color-white-default: rgb(241 241 241);
  --gc-color-white-default-contrast: rgb(40 40 40);
  --gc-color-white-tint: rgb(255 255 255);
  --gc-color-white-tint-contrast: rgb(82 82 82);
  --gc-color-white-tone: rgb(192 192 192);
  --gc-color-white-tone-contrast: rgb(51 51 51);
  --gc-color-white-shade: rgb(152 152 152);
  --gc-color-white-shade-contrast: rgb(26 26 26);
  --gc-color-white-opacity-10: rgb(241 241 241 / 10%);
  --gc-color-white-opacity-10-contrast: rgb(40 40 40 / 10%);
  --gc-color-skin-default: rgb(241 161 164);
  --gc-color-skin-default-contrast: rgb(70 10 12);
  --gc-color-skin-tint: rgb(247 202 203);
  --gc-color-skin-tint-contrast: rgb(89 13 15);
  --gc-color-skin-tone: rgb(178 128 135);
  --gc-color-skin-tone-contrast: rgb(64 39 42);
  --gc-color-skin-shade: rgb(160 97 106);
  --gc-color-skin-shade-contrast: rgb(51 31 34);
  --gc-color-transparent: rgb(21 44 38 / 0%);
  --gc-color-impar: var(--gc-color-step-25);
  --gc-color-par: var(--gc-color-step-50);
}
