/**
 * Este archivo contiene clases globales que afectan a toda la app
 * Por favor, NO editar este archivo salvo previa consulta.
 */

 @import "src/theme/design-system/mixins/mixins-app.scss";

/**
* Estilos base: ion-segment
*/
ion-segment {
    --background: var(--gc-color-step-50);
    ::part(indicator-background) {
        background-color:  var(--gc-color-accent-default);
    }

    ion-segment-button {
        &::part(native) {
            color: var(--gc-color-step-500);
            --gc-color-icon-fill: var(--gc-color-step-500);
            font-family: var(--gc-font-poppins-regular);
            font-size: min(var(--gc-size-font-s), var(--gc-size-font-max-s));
            display: flex;
            flex-direction: row;
        }
        &::before{
            content: none;
        }
        ion-icon {
            font-size: min(var(--gc-size-font-l), var(--gc-size-font-max-l));
            margin-right: var(--gc-size-margin-xs);
        }
    }

    .segment-button-checked::part(native){
        color: var(--gc-color-accent-default-contrast);
        --gc-color-icon-fill: var(--gc-color-accent-default-contrast);
        font-family: var(--gc-font-poppins-medium);
    }
}

/**
* Estilos base: ion-checkbox
*/
ion-checkbox {
    --checkbox-background-checked: var(--gc-color-accent-shade);
    --border-color-checked: var(--gc-color-accent-default);
    --checkmark-color: var(--gc-color-accent-shade-contrast);
    --checkmark-width: 3;
    --size: min(var(--gc-size-icon-m), var(--gc-size-icon-max-m));

    &::part(container) {
        padding: 1px;
      }
}

/**
* Estilos base: ion-content
*/
ion-content {
    --background: transparent;
    --color: var(--gc-color-layout-text);
    @include scrollbar();
}

/**
* Estilos base: ion-input
*/
ion-input, ion-textArea {

    &.has-focus{
        --highlight-color: var(--gc-color-accent-default) !important;
    }
    .label-text-wrapper {
        font-size: min(var(--gc-size-font-xl), var(--gc-size-font-max-xl));
    }
    .native-wrapper{
        border-bottom: 1px solid var(--gc-color-accent-default);
        font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
    }
    &.input-disabled {
        opacity: 1 !important;
        &.disabledOnEdit {
            opacity: 0.5 !important;
        }
    }
}

/**
* Estilos base: ion-toggle
*/
ion-toggle {
    &.toggle-disabled {
        opacity: 1 !important;
        &.disabledOnEdit {
            opacity: 0.5 !important;
        }
    }
}

/**
* Estilos base: ion-select
*/
ion-select {
    min-height: 0 !important;
    .native-wrapper{
    font-size: min(var(--gc-size-font-m), var(--gc-size-font-max-m));
    }
}