/**
  * Genera la estructura del button
  * @param $type (label o icon) : hace referencia a si el button solo tiene texto (label) o texto + icono (icon)
  * @param $size (s, m o l) : hace referencia al tamaño del button
  */
@mixin btn-size($type, $size) {
    //Tokens relativos al tamaño de padding de los botones.
    --gc-size-button-label-padding-l-bottom: var(--gc-size-padding-s);
    --gc-size-button-label-padding-l-right: var(--gc-size-padding-l);
    --gc-size-button-label-padding-l-top: var(--gc-size-padding-s);
    --gc-size-button-label-padding-l-left: var(--gc-size-padding-l);
    --gc-size-button-label-padding-m-bottom: var(--gc-size-padding-xs);
    --gc-size-button-label-padding-m-right: var(--gc-size-padding-m);
    --gc-size-button-label-padding-m-top: var(--gc-size-padding-xs);
    --gc-size-button-label-padding-m-left: var(--gc-size-padding-m);
    --gc-size-button-label-padding-s-bottom: var(--gc-size-padding-xxs);
    --gc-size-button-label-padding-s-right: var(--gc-size-padding-s);
    --gc-size-button-label-padding-s-top: var(--gc-size-padding-xxs);
    --gc-size-button-label-padding-s-left: var(--gc-size-padding-s);

    --gc-size-button-icon-padding-l-bottom: var(--gc-size-padding-m);
    --gc-size-button-icon-padding-l-right: var(--gc-size-padding-m);
    --gc-size-button-icon-padding-l-top: var(--gc-size-padding-m);
    --gc-size-button-icon-padding-l-left: var(--gc-size-padding-m);
    --gc-size-button-icon-padding-m-bottom: var(--gc-size-padding-s);
    --gc-size-button-icon-padding-m-right: var(--gc-size-padding-s);
    --gc-size-button-icon-padding-m-top: var(--gc-size-padding-s);
    --gc-size-button-icon-padding-m-left: var(--gc-size-padding-s);
    --gc-size-button-icon-padding-s-bottom: var(--gc-size-padding-xs);
    --gc-size-button-icon-padding-s-right: var(--gc-size-padding-xs);
    --gc-size-button-icon-padding-s-top: var(--gc-size-padding-xs);
    --gc-size-button-icon-padding-s-left: var(--gc-size-padding-xs);

    margin-inline-start: var(--gc-size-margin-cero);
    margin-inline-end: var(--gc-size-margin-cero);
    margin-top: var(--gc-size-margin-cero);
    margin-bottom: var(--gc-size-margin-cero);
    min-height: 0;

    &#accion, &#subAccion, &::part(native) {
        padding-top: var(--gc-size-button-#{$type}-padding-#{$size}-top);
        padding-bottom: var(--gc-size-button-#{$type}-padding-#{$size}-bottom);
        padding-left: var(--gc-size-button-#{$type}-padding-#{$size}-left);
        padding-right: var(--gc-size-button-#{$type}-padding-#{$size}-right);
        border-radius: var(--gc-size-border-radius-xs);
        font-size: min(var(--gc-size-font-#{$size}), var(--gc-size-font-max-#{$size}));
        font-family: var(--gc-font-poppins-semibold);
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
        box-shadow: none;
        cursor: pointer;
    }

    ion-icon{
        flex: 0 0 var(--gc-size-icon-#{$size});
        font-size: min(var(--gc-size-icon-#{$size}), var(--gc-size-icon-max-#{$size}));
        --ionicon-stroke-width: var(--gc-size-icon-stroke-l);
        @if $type == icon {
            margin: var(--gc-size-margin-cero);
        }
    }

    a, p, h1, h2, h3, h4, h5, h6 { margin: var(--gc-size-margin-cero); }
}

/**
  * Genera los estilos del button
  * @param $variant (accent, default, error, warn, success, info) : hace referencia al tipo de button que queremos crear
  * @param $fill (solid, outline o clear) : hace referencia al tipo de relleno del button
  */
@mixin btn-fill-variant($variant, $fill) {
    --gc-color-default-tone: var(--gc-color-accent-tone);
    --gc-color-default-default: var(--gc-color-accent-default);
    --gc-color-default-opacity-10: var(--gc-color-accent-opacity-10);
    --gc-color-default-default-contrast: var(--gc-color-accent-default-contrast);

    @if $fill == solid {
        --background-activated: var(--gc-color-#{$variant}-tone);
        --background-focused: var(--gc-color-#{$variant}-default);
        --background-hover: var(--gc-color-#{$variant}-tone);
        --background-activated-opacity: 1;
        --background-focused-opacity: 1;
        --background-hover-opacity: 1;

        &#accion, &::part(native) {
           --gc-color-icon-fill: var(--gc-color-#{$variant}-default-contrast);
            border: 2px solid var(--gc-color-#{$variant}-default);
            color: var(--gc-color-#{$variant}-default-contrast);
            background: var(--gc-color-#{$variant}-default);

            &:hover {
                border: 2px solid var(--gc-color-#{$variant}-tone);
                background: var(--gc-color-#{$variant}-tone);
            }

            &:focus {
                border: 2px solid var(--gc-color-#{$variant}-default);
                background: var(--gc-color-#{$variant}-default);
            }

            &:active {
                border: 2px solid var(--gc-color-#{$variant}-tone);
                background: var(--gc-color-#{$variant}-tone);
            }
        }
    }

    @if $fill == outline {
        --background-activated: var(--gc-color-#{$variant}-tone);
        --background-focused: var(--gc-color-transparent);
        --background-hover: var(--gc-color-#{$variant}-opacity-10);
        --background-activated-opacity: 1;
        --background-focused-opacity: 1;
        --background-hover-opacity: 1;

        &::part(native) {
            --gc-color-icon-fill: var(--gc-color-#{$variant}-default);
            border: 2px solid var(--gc-color-#{$variant}-default);
            color: var(--gc-color-#{$variant}-default);
            background: var(--gc-color-transparent);

            &:hover {
                border: 2px solid var(--gc-color-#{$variant}-tone);
                color: var(--gc-color-#{$variant}-tone);
                background: var(--gc-color-#{$variant}-opacity-10);
            }

            &:focus {
                border: 2px solid var(--gc-color-#{$variant}-default);
                color: var(--gc-color-#{$variant}-default);
                background: var(--gc-color-transparent);
            }

            &:active {
                border: 2px solid var(--gc-color-#{$variant}-tone);
                color: var(--gc-color-#{$variant}-default-contrast);
                background: var(--gc-color-#{$variant}-tone);
            }

        }
    }

    @if $fill == clear {
        --background-activated: var(--gc-color-#{$variant}-tone);
        --background-focused: var(--gc-color-transparent);
        --background-hover: var(--gc-color-#{$variant}-opacity-10);
        --background-activated-opacity: 1;
        --background-focused-opacity: 1;
        --background-hover-opacity: 1;

        &#subAccion, &::part(native) {
            --gc-color-icon-fill: var(--gc-color-#{$variant}-default);
            border: 2px solid var(--gc-color-transparent);
            color: var(--gc-color-#{$variant}-default);
            background: var(--gc-color-transparent);

            &:hover {
                border: 2px solid var(--gc-color-#{$variant}-opacity-10);
                color: var(--gc-color-#{$variant}-default);
                background: var(--gc-color-#{$variant}-opacity-10);
            }

            &:focus {
                border: 2px solid var(--gc-color-transparent);
                color: var(--gc-color-#{$variant}-default);
                background: var(--gc-color-transparent);
            }

            &:active {
                border: 2px solid var(--gc-color-#{$variant}-tone);
                color: var(--gc-color-#{$variant}-default-contrast);
                background: var(--gc-color-#{$variant}-tone);
            }
        }
    }

}

/**
  * Genera el código visual para el componente button
  * @param $type (label o icon) : hace referencia a si el button solo tiene texto (label) o texto + icono (icon)
  * @param $size (s, m o l) : hace referencia al tamaño del button
  * @param $variant (accent, default, error, warn, success, info) : hace referencia al tipo de button que queremos crear
  * @param $fill (solid, outline o clear) : hace referencia al tipo de relleno del button
  */
@mixin btn($type, $size, $variant, $fill) {
    word-break: auto-phrase;
    white-space: normal;
    text-wrap: pretty;
    @include btn-size($type, $size);
    @include btn-fill-variant($variant, $fill);
}
